<template>
  <v-app-bar app color="primary" flat dark>
    <v-container class="py-0 fill-height d-flex justify-space-between container" fluid>
      <v-toolbar-title class="pr-4">
        <div>
          <v-img alt="G1 Racesoft Logo" src="@/assets/G1logo.svg"/>
        </div>
      </v-toolbar-title>
      <router-link :to="{name: 'Dashboard'}" active-class="secondary--text transparent"
                   class="font-weight-regular white--text text-capitalize px-2 mx-4 text-decoration-none">
        Dashboard
        </router-link>
      <router-link :to="{name: 'Analytics'}" active-class="secondary--text transparent"
                   class="font-weight-regular white--text text-capitalize px-2 mx-4 text-decoration-none">
        Analytics
      </router-link>
      <router-link :to="{name: 'member'}" active-class="secondary--text transparent"
                   class="font-weight-regular white--text text-capitalize px-2 mx-4 text-decoration-none">
        Members
      </router-link>
      <router-link :to="{name: 'Data'}" active-class="secondary--text transparent"
                   class="font-weight-regular white--text text-capitalize px-2 text-decoration-none">
        Horses/Runners
      </router-link>
      <router-link :to="{name: 'StallionMatch'}" active-class="secondary--text transparent"
                   class="font-weight-regular white--text text-capitalize px-2 mx-4 text-decoration-none">
        Stallion Match
      </router-link>
      <router-link :to="{name: 'Reports'}" active-class="secondary--text transparent"
                   class="font-weight-regular white--text text-capitalize px-2 mx-4 text-decoration-none">
        Reports
      </router-link>
      <a class="font-weight-regular white--text text-capitalize px-2 text-decoration-none">
        Enquiries
      </a>
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <a class="font-weight-regular white--text text-capitalize px-2 text-decoration-none" v-on="on" v-bind="attrs">
            Settings
          </a>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <a>
                Configuration
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{name: 'Logs'}">
                Logs
              </router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{name: 'Admin'}">
                Admin Settings
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="36" color="accent" v-on="on" v-bind="attrs" :title="user.fullName">
            {{ user.firstName[0] }} {{user.lastName[0]}}
          </v-avatar>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-content>
              <v-list-item-title>
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>

export default {
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
      location.reload()
    }
  }
}
</script>
