




































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Socket} from 'vue-socket.io-extended'
import {Country, HorseFinderResource} from '@/store/types'
import throttle from 'lodash.throttle'


@Component
export default class HorseFinder extends Vue {
  exactMatch = true
  orderByYob = false
  page = 0

  dialog = false
  loading = false
  public name = ''
  public horses: Array<HorseFinderResource> = []
  header = [
    {text: 'HORSE', value: 'horse'},
    {text: 'SIRE x DAM', value: 'sire_x_dam'}
  ]

  requestFetchDataThrottled: ReturnType<typeof throttle> = throttle(this.requestFetchData.bind(this), 500)

  requestFetchData(name: string) {
    this.$socket.client.emit('horses_finder', {name, exactMatch: this.exactMatch, page: this.page, orderByYob: this.orderByYob})
  }

  @Watch('exactMatch')
  @Watch('orderByYob')
  @Watch('name')
  onNameChange() {
    if (this.name.length > 3) {
      this.loading = true
      this.page = 0;
      this.requestFetchDataThrottled(this.name)
    } else {
      this.horses = []
    }
  }

  @Socket('horses_finder')
  onHorsesChange(items: {
    result: Array<HorseFinderResource>;
    name: string;
  }) {
    if (items.name === this.name) {
      this.loading = false
      this.horses = items.result
    }
  }

  select(item: number) {
    this.$emit('select', item)
    this.$emit('input', false)
  }

  @Watch('value')
  onValueChange() {
    this.dialog = this.value
  }

  get countries() {
    return this.$store.getters.countries
  }

  getCountryName(countryID: number) {
    return this.countries.find((c: Country) => c.CountryID === countryID)?.CountryCode || '-'
  }

  @Prop()
  value!: boolean

  onKeyPress(e: any) {
    if (e.code === "Escape") {
      this.$emit('input', false)
    }
  }

  nextPage() {
    this.page++
    this.loading = true
    this.requestFetchDataThrottled(this.name)
  }

  previousPage() {
    this.page--
    this.loading = true
    this.requestFetchDataThrottled(this.name)
  }
}
