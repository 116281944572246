


































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {
  DynamicSummaryClientManifest,
  DynamicSummaryColumnsSearchType,
  DynamicSummaryDataSearchRequest
} from '@/store/types'
import DatePicker from '@/components/DatePicker.vue'
import NumberRangePicker from '@/components/NumberRangePicker.vue'

@Component({
  components: {NumberRangePicker, DatePicker}
})
export default class SearchView extends Vue {
  @Prop({required: true, type: Object})
  public manifest!: DynamicSummaryClientManifest

  @Prop({required: false, type: Boolean, default: true})
  public loading!: number

  @Prop({required: true, type: String})
  serviceName!: string

  public values: { [key: string]: string | null | boolean } = {}
  public calenderValues: { [key: number]: boolean } = {}
  public groupByItems: { [key: string]: Array<string> } = {}

  isStringField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.text_like_right ||
        type === DynamicSummaryColumnsSearchType.text_like_left ||
        type === DynamicSummaryColumnsSearchType.text_like_full ||
        type === DynamicSummaryColumnsSearchType.text_exact
  }

  isNumberField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.number_equal ||
        type === DynamicSummaryColumnsSearchType.number_greater_than ||
        type === DynamicSummaryColumnsSearchType.number_less_than
  }

  isSelectField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.text_group_by
  }

  isSwitchField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.bit
  }

  isCheckBoxField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.is_null
  }

  isNumberRangeField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.number_range
  }

  isDateField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.date_exact
  }

  isDateRangeField(type: DynamicSummaryColumnsSearchType) {
    return type === DynamicSummaryColumnsSearchType.date_range
  }

  search() {
    const searchRequests: Array<DynamicSummaryDataSearchRequest> = []
    const searchParams: Record<string, string> = {}
    for (const si of this.manifest.search) {
      const value = this.values[si.value + '_' + si.type]
      if (value) {
        if ((typeof value === 'string' && value.length > 0) || typeof value === 'boolean') {
          searchRequests.push({
            type: si.type,
            columnName: si.value,
            value
          })
          searchParams[si.value] = value.toString()
        }
      }
    }
    try {
      this.$router.push({
        path: this.$route.path,
        query: searchParams
      })
    } catch (e) {
      console.log(e)
    }
    this.$emit('search', searchRequests)
  }

  reset() {
    this.manifest.search.forEach(si => {
      this.values[si.value + '_' + si.type] = null
    })
    this.search()
  }

  exportData() {
    this.$emit('export')
  }

  get searchAbleColumnsName() {
    return this.manifest.search.map(i => i.value)
  }

  refreshTrigger = true

  created() {
    const queries = this.$route.query as Record<string, string>
    let runSearch = false
    this.manifest.search.forEach(si => {
      if (si.type === DynamicSummaryColumnsSearchType.text_group_by) {
        this.groupByItems[si.value] = []
        if (!si.dependOn) {
          this.$socket.client.emit('dynamic_summary_group_by_value', {
            serviceName: this.serviceName,
            columnName: si.value
          })
        }
      }
      if (queries[si.value]) {
        runSearch = true
        if (si.type === 'bit') {
          this.values[si.value + '_' + si.type] = Boolean(queries[si.value])
        } else {
          this.values[si.value + '_' + si.type] = queries[si.value]
        }
      }
    })

    if (runSearch) {
      setTimeout(() => {
        this.search()
      })
    }

    this.$socket.client.on('dynamic_summary_group_by_value',
        (res: { data: Array<string>; serviceName: string; columnName: string }) => {
          if (this.serviceName === res.serviceName && this.searchAbleColumnsName.includes(res.columnName)) {
            const tmp = res.data.sort((a, b) => a < b ? -1 : 1)
            this.$set(this.groupByItems, res.columnName, tmp)
            this.refreshTrigger = false
            setTimeout(() => {
              this.refreshTrigger = true
            })
          }
        })
  }

  calenderViewHandler(id: number, value: boolean) {
    if (value) {
      for (const key in this.calenderValues) {
        if (key.toString() === id.toString()) {
          this.calenderValues[key] = true
        } else {
          this.calenderValues[key] = false
        }
      }
      this.$emit('calenderView', id)
    } else {
      this.$emit('calenderView', false)
    }
  }

  groupByValueUpdated(id: number, value: string) {
    const items = this.manifest.search.filter(s => s.dependOn === id);
    for (const item of items) {
      this.$socket.client.emit('dynamic_summary_group_by_value', {
        serviceName: this.serviceName,
        columnName: item.value,
        dependOnValue: value,
        searchId: item.id,
      })
      this.values[item.value + '_' + item.type] = null
    }
  }
}
