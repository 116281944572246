










































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import HorseFinder from '@/components/HorseFinder.vue'
import {Socket} from 'vue-socket.io-extended'
import {HorseFinderResource, PedigreeResource, PedigreeRowResource, PedigreeRowResourceNew} from '@/store/types'
import HorseDetailUpdateMini from '@/components/HorseDetailUpdateMini.vue'

interface Horse {
  ID: number;
  Name: string;
}

@Component({
  components: {HorseDetailUpdateMini, HorseFinder}
})
export default class Pedigree extends Vue {
  @Prop({
    required: true,
    type: Number
  })
  value!: number

  horseDialog = false
  editDialog = false
  loading = false

  get showFromDepth() {
    return this.showAbleDepth[0]
  }

  get showUntilDepth() {
    return this.showAbleDepth[1] + 1
  }

  twoToPow(i: number) {
    return Math.pow(2, i)
  }

  get depthNames() {
    return [...Array(8).keys()].map((d) => `Gen ${Number(d) + 1}`)
  }

  get paddingMode() {
    if (this.showUntilDepth - this.showFromDepth <= 3) {
      return 'padding: 16px 0'
    } else return null
  }

  showAbleDepth = [0, 4]

  getTooltip(i: number, j: number) {
    const pos = this.getGenerationByRowAndCol(i, j)
    const info = this.pedigree[pos]
    if (info) {
      return `${pos}: ${info.HorseName} \r\n(${info.HorseCoBCode}, ${info.HorseYob})`
    } else return pos
  }

  @Watch('value')
  onValueChange(newValue: number, oldValue: number) {
    if (oldValue <= 0) {
      this.onHorseIdChange([0, 4])
    } else {
      this.onHorseIdChange()
    }
  }

  onHorseIdChange(showAbleDepth = this.showAbleDepth) {
    if (this.value > 0) {
      this.showAbleDepth = showAbleDepth
      this.loading = true
      this.$socket.client.emit('horses_pedigree', {horseId: this.value})
    }
  }

  pedigree: PedigreeResource = {}

  @Socket('horses_pedigree_update')
  @Socket('horses_pedigree')
  onPedigreeLoaded(value: Array<PedigreeRowResource>) {
    for (const key in this.pedigree) {
      this.pedigree[key] = null
    }
    value.forEach(i => {
      this.pedigree[i.HorsePosition] = i
    })
    this.loading = false
  }

  created() {
    this.onHorseIdChange()
  }

  getGenerationByRowAndCol(row: number, size: number): string {
    const res = (row >>> 0).toString(2)
    let prefix = ''
    for (let i = 0; i < size - res.length; i++) {
      prefix += '0'
    }
    return (prefix + res).replace(/0/g, 'S').replace(/1/g, 'D')
  }

  selectedRow: PedigreeRowResource | false = false
  selectedRowPosition = ''

  selectHorseAndEdit(horse: PedigreeRowResource, position: string) {
    this.selectedRow = horse
    this.selectedRowPosition = position
    this.$nextTick(() => {
      this.editDialog = true
    })
  }

  selectHorseAndReplace(horse: PedigreeRowResource, position: string) {
    this.selectedRow = horse
    this.selectedRowPosition = position
    this.$nextTick(() => {
      this.horseDialog = true
    })
  }

  loadFullData() {
    this.editDialog = false
    this.$nextTick(() => {
      if (this.selectedRow) {
        this.$router.push(
            {name: 'Data.horse.update', params: {id: this.selectedRow.HorseID.toString()}})
      }
    })
  }

  pedigreeUpdateContext = {
    pos: '',
    oldName: '',
    newName: '',
    req: {
      horseID: 0,
      damID: 0,
      sireID: 0,
      pedigreeHorseID: 0,
    }
  }

  confirmDialog = false

  updatePedigree(horse: HorseFinderResource) {
    if (this.selectedRowPosition) {
      if (horse.HorseSex === 'F' && this.selectedRowPosition.endsWith('S') || horse.HorseSex === 'M' && this.selectedRowPosition.endsWith('D')) {
        return alert('Wrong Horse Sex!')
      }
      let horseID = 0
      let damID = 0
      let sireID = 0

      if (this.selectedRowPosition.length === 1) {
        horseID = this.value
      } else {
        horseID = this.pedigree[this.selectedRowPosition.slice(0, -1)]?.HorseID || 0
      }
      const tmp = this.selectedRowPosition.slice(-1)
      if (tmp === 'S') {
        sireID = horse.HorseID
        damID = this.pedigree[this.selectedRowPosition.slice(0, -1) + 'D']?.HorseID || 0
      } else {
        damID = horse.HorseID
        sireID = this.pedigree[this.selectedRowPosition.slice(0, -1) + 'S']?.HorseID || 0
      }
      if (horseID !== 0) {
        this.pedigreeUpdateContext.req = {
          horseID,
          damID,
          sireID,
          pedigreeHorseID: this.value
        }
        this.pedigreeUpdateContext.pos = this.selectedRowPosition
        this.pedigreeUpdateContext.oldName = this.selectedRow ? this.selectedRow.HorseName : ''
        this.pedigreeUpdateContext.newName = horse.HorseName
        this.confirmDialog = true
      }
    }
  }

  doUpdate() {
    this.confirmDialog = false
    this.$socket.client.emit('horses_pedigree_update', this.pedigreeUpdateContext.req)
    this.loading = true
  }

  refreshPedigree() {
    this.editDialog = false
    this.onHorseIdChange()
  }

  getColor(horse: PedigreeRowResourceNew, pos: string) {
    if (
        ((pos.endsWith('S') && horse.HorseSex === 'F') || (pos.endsWith('D') && horse.HorseSex === 'M')) ||
        (!(horse.HorseCoBCode && horse.HorseCoBCode.length > 0)) || (!(horse.HorseYob && horse.HorseYob.toString().length > 0)) ||
        (!(horse.HorseYob && !isNaN(Number(horse.HorseYob)) && Number(horse.HorseYob) > 1000)) ||
        (horse.HorseCoBCode === 'NRF' || horse.HorseCoBCode === 'UNK')
    ) {
      return 'has-error-text'
    }
    if (pos !== '' && pos !== 'h') {
      if (pos.endsWith('S')) {
        if (Number(this.pedigree[pos.slice(0, -1)]?.HorseYob) - Number(horse.HorseYob) > 30) {
          return 'has-error-2-text'
        }
      }
      if (pos.endsWith('D')) {
        if (Number(this.pedigree[pos.slice(0, -1)]?.HorseYob) - Number(horse.HorseYob) > 26) {
          return 'has-error-2-text'
        }
      }
    }
    if (!horse.HorseVerifiedBy) {
      return 'has-warn-text'
    }
    if (!horse.HorseColorID || horse.HorseColorID < 1) {
      return 'color-missing-text'
    }
    return ''
  }

  getHorseStyle(horse: PedigreeRowResourceNew){
    if (horse.HorseIsProtected) {
      return 'font-weight-bold'
    } else return ''
  }
}
