


















































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { copyToClipboard, HorseDetailResource, HorseSummaryResource } from '@/store/types'
import { Socket } from 'vue-socket.io-extended'

@Component
export default class HorseDetail extends Vue {
  @Prop({ default: false })
  value!: boolean

  @Prop({ required: true })
  item!: HorseSummaryResource

  details: HorseDetailResource = {
    HorseID: 0,
    HorseName: '',
    HorseCoBID: 0,
    HorseCoBCode: '',
    HorseYoB: '',
    HorseSex: 'F',
    HorseColour: '',
    HorseColourID: 0,
    HorseSireID: 0,
    HorseSireName: '',
    HorseDamID: 0,
    HorseDamName: '',
    HorseG1Count: 0,
    HorseG2Count: 0,
    HorseG3Count: 0,
    HorseG4Count: 0,
    HorseCreatedAt: '',
    HorseEnteredBy: '',
    HorseVerifiedAt: '',
    HorseVerifiedBy: '',
    HorseFrom: '',
    HorseIsGelding: '',
    HorseNameKey: '',
    HorseSoundexCode: '',
    HorseSalePrice: '',
    HorseSaleCurrency: '',
    HorseAccuracy: 0,
    HorsePrizeMoney: '',
    HorsePrizeMoneyCurrency: '',
    HorseTotalWins: 0,
    HorseTotalStarts: 0,
    HorseIsThoroughbred: false,
    HorseUpdatedBy: '',
    HorseIsEligible: false,
    HorseRunCount: 0,
    HorseWinCount: 0,
    HorseFamilyTrackingCount: 0,
    HorseDependencyCount: 0,
    HorseComments: '',
    HorseIsProtected: false
  }

  dialog = false

  loading = false

  horseDetailHash = 0

  @Watch('value')
  onValueChange (value: boolean) {
    this.dialog = value
    if (!value) {
      setTimeout(this.reset.bind(this), 1000)
    } else {
      this.loading = true
      this.horseDetailHash = Math.random()
      this.$socket.client.emit('horses_details', { horseId: this.item.HorseID, userData: this.horseDetailHash })
    }
  }

  @Socket('horses_details')
  farmDetailLoaded (detail: { result: HorseDetailResource; userData: number }) {
    if (detail.userData === this.horseDetailHash) {
      this.details = detail.result
      this.loading = false
    }
  }

  @Watch('dialog')
  onDialogChange (dialog: boolean) {
    if (!dialog) {
      this.close()
    }
  }


  reset () {
    // todo
  }

  close () {
    this.$emit('input', false)
  }

  created () {
    this.onValueChange(this.value)
  }

  copyToClipboard (text: string) {
    copyToClipboard(text)
  }

  nullToZero (item: null | number) {
    return item === null ? 0 : item
  }

  nullToUnknown (item: null | boolean | string) {
    return item === null ? '-' : item
  }

  dependencyDialog = false
  dependencyLoading = false
  dependencyData: Array<unknown> = []

  openDependencyDialog () {
    this.dependencyLoading = true
    this.$socket.client.emit('horses_dependency_direct', {
      horseID: this.details.HorseID
    })
  }

  @Socket('horses_dependency_direct')
  onDependencyLoaded (data: Array<unknown>) {
    this.dependencyData = data
    this.dependencyLoading = false
    setTimeout(() => {
      this.dependencyDialog = true
    })
  }

  get dependencyDataHeader () {
    if (this.dependencyData.length > 0) {
      const result = []
      const item: any = this.dependencyData[0]
      for (const key in item) {
        result.push({
          text: key.replace(/([A-Z])/g, ' $1'),
          value: key,
        })
      }
      return result
    }
    return []
  }

  goToHorse (horse: any) {
    this.dependencyDialog = true
    this.dialog = false
    this.$router.push({
      name: 'Data.horse.update',
      params: {
        id: horse.HorseID
      }
    })
  }
}
