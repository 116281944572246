











import {Vue, Component} from 'vue-property-decorator';
import DynamicSummaryPage from '@/components/DynamicSummary/DynamicSummaryPage.vue';
import NewSalesCatalog from "@/components/NewSalesCatalog.vue";
import {SalesSummaryResource} from "@/store/types";

@Component({
  components: {NewSalesCatalog, DynamicSummaryPage}
})
export default class SalesCatalog2 extends Vue {

  newSalesDialog = false

  openDetail({SalesID}: any) {
    this.$router.push({
      name: 'Report.SalesCatalog.Item.Data',
      params: {
        id: SalesID.toString()
      }
    })
  }

  created () {
    this.$store.dispatch('loadSalesSummary')
    this.$store.dispatch('loadSalesCompanies')
    this.$store.dispatch('loadSalesTypes')
    this.$store.dispatch('loadSalesInfos')
  }

  salesCreated() {
    this.newSalesDialog = false
    this.$store.dispatch('loadSalesSummary')
    this.$store.dispatch('loadSalesCompanies')
    this.$store.dispatch('loadSalesTypes')
    this.$store.dispatch('loadSalesInfos')
  }

  openNewSales() {
    this.newSalesDialog = true
  }
}
