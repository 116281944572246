




































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Socket } from 'vue-socket.io-extended';
import { Country, HorseFinderResource, PedigreeResourceTemporaryAble } from '@/store/types';
import HorseFinder from '@/components/HorseFinder.vue';
import NewHorse from '@/views/Data/NewHorse.vue';
import HorseDetailUpdateMini from '@/components/HorseDetailUpdateMini.vue';

@Component({
  components: { HorseDetailUpdateMini, NewHorse, HorseFinder }
})
export default class SalesCatalogData extends Vue {
  saleId = 0;
  selectedLot: number | null = null;

  @Watch('$route')
  onRouteChange () {
    this.saleId = Number(this.$route.params.id);
    this.$socket.client.emit('sales_temp_info', { saleId: this.saleId });
    this.dataItems = [];
    this.dataLoading = false;
    this.fromLot = '';
    this.toLot = '';
    this.unVerified = false;
    this.verified = false;
    this.notMatched = false;
    this.notMatchedSD = false;
    this.withdrawn = false;
  }

  created () {
    this.onRouteChange();
  }

  openUploadDialog () {
    (this.$refs.fileInput as HTMLElement).click();
  }

  loading = false;
  loadingProgress = 0;

  uploadImportedFile () {
    const files = (this.$refs.fileInput as HTMLInputElement).files;
    if (files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result;
        if (text) {
          this.loading = true;
          this.loadingProgress = 0;
          this.$socket.client.emit('sales_import', { salesId: this.saleId, data: text });
        }
      };
      reader.readAsText(files[0]);
    }
  }


  @Socket('sales_import')
  dataHasImported () {
    this.loading = false;
    this.$socket.client.emit('sales_temp_info');
  }

  @Socket('sales_import_process')
  updateLoading (val: string) {
    this.loadingProgress = Number(val);
  }

  toolbarText = '';

  @Socket('sales_temp_info')
  updateSalesInfo (data: { salesName: string; tempBookCount: number; tempLotCount: number; salesCode: string; minLotNumber: number; maxLotNumber: number }) {
    this.toolbarText = `${data.salesName} (${data.tempBookCount} Books, ${data.tempLotCount} Lots)`;
    this.salesCode = data.salesCode;
    this.fromLot = data.minLotNumber.toString();
    this.toLot = data.maxLotNumber.toString();
    this.requestData();
  }

  get countries () {
    return this.$store.getters.countries;
  }

  dataItems: Array<any> = [];
  selectedItem: any = false;
  dataLoading = false;
  fromLot = '';
  toLot = '';
  unVerified = false;
  verified = false;
  notMatched = false;
  notMatchedSD = false;
  withdrawn = false;

  requestData () {
    this.dataLoading = true;
    this.selectedItem = false;
    this.dataItems = [];
    this.$socket.client.emit('sales_temp_lots_list', {
      saleId: this.saleId,
      from: Number(this.fromLot),
      to: Number(this.toLot),
      unVerified: this.unVerified,
      verified: this.verified,
      notMatched: this.notMatched,
      notMatchedSD: this.notMatchedSD,
      withdrawn: this.withdrawn
    });
  }

  @Socket('sales_temp_lots_list')
  acquireData (data: Array<any>) {
    this.dataItems = data;
    this.dataLoading = false;
  }

  get sirePlaceholder () {
    if (this.selectedItem) {
      if (this.selectedItem.g1SireID) {
        const item = this.selectedItem;
        const country = this.countries.find((d: Country) => d.CountryID === item.G1SireCoBID)?.CountryCode;
        const sCountry = this.countries.find((d: Country) => d.CountryID === item.G1SireSireCoBID)?.CountryCode;
        const dCountry = this.countries.find((d: Country) => d.CountryID === item.G1SireDamCoBID)?.CountryCode;
        return `${item.G1SireName} (${item.G1SireYoB}, ${country}) (${item.G1SireSireName} (${item.G1SireSireYoB}, ${sCountry}) x ${item.G1SireDamName} (${item.G1SireDamYoB}, ${dCountry}))`;
      }
    }
    return '';
  }

  get damPlaceholder () {
    if (this.selectedItem) {
      if (this.selectedItem.g1DamID) {
        const item = this.selectedItem;
        const country = this.countries.find((d: Country) => d.CountryID === item.G1DamCoBID)?.CountryCode;
        const sCountry = this.countries.find((d: Country) => d.CountryID === item.G1DamSireCoBID)?.CountryCode;
        const dCountry = this.countries.find((d: Country) => d.CountryID === item.G1DamDamCoBID)?.CountryCode;
        return `${item.G1DamName} (${item.G1DamYoB}, ${country}) (${item.G1DamSireName} (${item.G1DamSireYoB}, ${sCountry}) x ${item.G1DamDamName} (${item.G1DamDamYoB}, ${dCountry}))`;
      }
    }
    return '';
  }

  get horsePlaceholder () {
    if (this.selectedItem) {
      const item = this.selectedItem;
      if (this.selectedItem.g1HorseID) {
        const country = this.countries.find((d: Country) => d.CountryID === item.G1HorseCoBID)?.CountryCode;
        return `${item.G1HorseName} (${item.G1HorseYoB}, ${country ? country : '-'})`;
      } else if (item.horseName) {
        return `${item.horseName} (${item.horseYoB}, ${item.horseCoB || '-'})`;
      } else {
        const country = this.countries.find((d: Country) => d.CountryID === item.G1HorseIDFromCatalog)?.CountryCode;
        return `${this.salesCode}-${item.lotCode} (${item.horseSex}, ${item.horseYoB}, ${country ? country : '-'})`;
      }
    }
    return ``;
  }

  newHorseDialog = false;
  horseFinderDialog = false;
  selectedHorseFinderMode: 's' | 'd' | 'h' = 'h';
  tempHorse: HorseFinderResource | false = false;
  verifyLoading = false;

  openHorseFinder (mode: 's' | 'd' | 'h') {
    if (this.selectedItem) {
      (this.$refs.horseFinder as HorseFinder).horses = [];
      this.horseFinderDialog = true;
      this.selectedHorseFinderMode = mode;
      if (mode === 's') {
        setTimeout(() => {
          (this.$refs.horseFinder as HorseFinder).name = this.selectedItem.sireName || '';
        }, 100);
      }
      if (mode === 'd') {
        setTimeout(() => {
          (this.$refs.horseFinder as HorseFinder).name = this.selectedItem.damName || '';
        }, 100);
      }
      if (mode === 'h') {
        setTimeout(() => {
          (this.$refs.horseFinder as HorseFinder).name = this.selectedItem.horseName || `${this.salesCode}-${this.selectedItem.lotNumber}`;
        }, 100);
      }
    }
  }

  horseHasSelected (horse: HorseFinderResource) {
    this.verifyLoading = true;
    this.tempHorse = horse;
    this.$socket.client.emit('sales_temp_info_update', {
      lotTempId: this.selectedItem.salesLotTempID,
      horseId: horse.HorseID,
      pos: this.selectedHorseFinderMode
    });
  }

  @Socket('sales_temp_info_update')
  horseHasUpdated () {
    if (this.autoVerify) {
      this.tryVerify();
      this.autoVerify = false;
      return;
    }
    const index = this.dataItems.findIndex(d => d.salesLotTempID === this.selectedItem.salesLotTempID);
    if (index >= 0 && this.tempHorse) {
      if (this.selectedHorseFinderMode === 's') {
        this.dataItems[index].g1SireID = this.tempHorse.HorseID;
        this.dataItems[index].G1SireName = this.tempHorse.HorseName;
        this.dataItems[index].G1SireYoB = this.tempHorse.HorseYearOfBirth;
        this.dataItems[index].G1SireCoBID = this.tempHorse.HorseNationality;
      }
      if (this.selectedHorseFinderMode === 'd') {
        this.dataItems[index].g1DamID = this.tempHorse.HorseID;
        this.dataItems[index].G1DamName = this.tempHorse.HorseName;
        this.dataItems[index].G1DamYoB = this.tempHorse.HorseYearOfBirth;
        this.dataItems[index].G1DamCoBID = this.tempHorse.HorseNationality;
      }
      if (this.selectedHorseFinderMode === 'h') {
        this.dataItems[index].g1HorseID = this.tempHorse.HorseID;
        this.dataItems[index].G1HorseName = this.tempHorse.HorseName;
        this.dataItems[index].G1HorseYoB = this.tempHorse.HorseYearOfBirth;
        this.dataItems[index].G1HorseCoBID = this.tempHorse.HorseNationality;
      }
    }
    this.verifyLoading = false;
  }

  newHorseInfoDialog = false;
  salesCode = '';

  tryVerify () {
    if (this.selectedItem) {
      if (this.isNamedHorse(this.selectedItem) || this.selectedItem.g1SireID && this.selectedItem.g1DamID) {
        if (this.selectedItem.g1HorseID) {
          this.verifyLoading = true;
          this.$socket.client.emit('sales_lot_verify', { lotTempId: this.selectedItem.salesLotTempID });
        } else {
          if (confirm('You Didn\'t Select Horse, Do You Want Create it now?')) {
            this.newHorseDialog = true;
            this.selectedHorseFinderMode = 'h';
            setTimeout(async () => {
              (this.$refs.newHorse as NewHorse).selectedRowPosition = 'S';
              await (this.$refs.newHorse as NewHorse).updatePedigree({
                HorseID: this.selectedItem.g1SireID,
                HorseNationality: this.selectedItem.G1SireCoBID,
                HorseYearOfBirth: this.selectedItem.G1SireYoB,
                HorseName: this.selectedItem.G1SireName,
                HorseSex: this.selectedItem.HorseSex
              });
              (this.$refs.newHorse as NewHorse).selectedRowPosition = 'D';
              await (this.$refs.newHorse as NewHorse).updatePedigree({
                HorseID: this.selectedItem.g1DamID,
                HorseNationality: this.selectedItem.G1DamCoBID,
                HorseYearOfBirth: this.selectedItem.G1DamYoB,
                HorseName: this.selectedItem.G1DamName,
                HorseSex: this.selectedItem.HorseSex
              });
              (this.$refs.newHorse as NewHorse).temporaryHorses['H'] = {
                HorseID: 0,
                HorseName: this.selectedItem.horseName || this.salesCode + '-' + this.selectedItem.lotCode,
                HorseCoBID: this.selectedItem.G1HorseIDFromCatalog,
                HorseCoBCode: '',
                HorseYoB: this.selectedItem.horseYoB || '',
                HorseSex: this.selectedItem.horseSex,
                HorseColour: this.selectedItem.g1horseColourCode,
                HorseColourID: this.selectedItem.g1horseColourID,
                HorseIsProtected: false,
                HorseSireID: 0,
                HorseSireName: '',
                HorseDamID: 0,
                HorseDamName: '',
                HorseG1Count: 0,
                HorseG2Count: 0,
                HorseG3Count: 0,
                HorseG4Count: 0,
                HorseCreatedAt: '',
                HorseEnteredBy: '',
                HorseVerifiedAt: '',
                HorseVerifiedBy: '',
                HorseFrom: `SALE_${this.salesCode}`,
                HorseIsGelding: '',
                HorseNameKey: '',
                HorseSoundexCode: '',
                HorseSalePrice: '',
                HorseSaleCurrency: '',
                HorseAccuracy: 0,
                HorsePrizeMoney: '',
                HorsePrizeMoneyCurrency: '',
                HorseTotalWins: 0,
                HorseTotalStarts: 0,
                HorseIsThoroughbred: true,
                HorseUpdatedBy: '',
                HorseIsEligible: null,
                HorseRunCount: 0,
                HorseWinCount: 0,
                HorseFamilyTrackingCount: 0,
                HorseDependencyCount: 0,
                HorseComments: '',
              };
              (this.$refs.newHorse as NewHorse).createHorse('H');
            }, 50);
          }
        }
      } else {
        alert('Please Select Dam And Sire.');
      }
    }
  }

  verifiedSnackbar = false;

  @Socket('sales_lot_verify')
  lotHasVerified () {
    this.verifiedSnackbar = true;
    const index = this.dataItems.findIndex(d => d.salesLotTempID === this.selectedItem.salesLotTempID);
    if (index >= 0) {
      this.dataItems[index].isVerifiedLot = true;
    }
    this.verifyLoading = false;
  }

  autoVerify = false;

  newHorseCreated (horseDetail: PedigreeResourceTemporaryAble) {
    this.newHorseDialog = false;
    const index = this.dataItems.findIndex(d => d.salesLotTempID === this.selectedItem.salesLotTempID);
    if (index >= 0) {
      if (this.selectedHorseFinderMode === 'h') {
        this.dataItems[index].g1HorseID = horseDetail.HorseID;
        this.dataItems[index].G1HorseName = horseDetail.HorseName;
        this.dataItems[index].G1HorseYoB = horseDetail.HorseYob;
        this.dataItems[index].G1HorseCoBID = horseDetail.HorseCoBCode;
        this.selectedItem.g1HorseID = horseDetail.HorseID;
        this.selectedItem.G1HorseName = horseDetail.HorseName;
        this.selectedItem.G1HorseYoB = horseDetail.HorseYob;
        this.selectedItem.G1HorseCoBID = horseDetail.HorseCoBCode;
        this.selectedItem.G1HorseIDFromCatalog = horseDetail.HorseCoBCode;
        this.selectedItem.HorseSex = horseDetail.HorseSex;
      }
      this.$socket.client.emit('sales_temp_info_update', {
        lotTempId: this.selectedItem.salesLotTempID,
        horseId: horseDetail.HorseID,
        pos: 'h'
      });
      this.autoVerify = true;
    }
  }

  openEdit (horseId: number) {
    const routeData = this.$router.resolve({ name: 'Data.horse.update', params: { id: horseId.toString() } });
    window.open(routeData.href, '_blank');
  }

  isNamedHorse (horse: any) {
    return horse.isNamed;
  }

  @Socket('sales_import:error')
  handleError (error: Error) {
    alert(error.message);
  }

  optionsLoading = false

  @Socket('sales_temp_info_update_options')
  onDataUpdate() {
    this.optionsLoading = false
  }

  updateOptions(value: boolean) {
    this.optionsLoading = true
    this.$socket.client.emit('sales_temp_info_update_options', {
      saleId: this.saleId,
      lotCode: this.selectedItem.lotCode,
      isWithdrawn: value || false,
      isNamed: this.selectedItem.isNamed
    })
  }
  updateOptions2(value: boolean) {
    this.optionsLoading = true
    this.$socket.client.emit('sales_temp_info_update_options', {
      saleId: this.saleId,
      lotCode: this.selectedItem.lotCode,
      isWithdrawn: this.selectedItem.isWithdrawn,
      isNamed: value || false
    })
  }

  matchTypeParser(matchType?: string) {
    if (matchType) {
      matchType = matchType.toUpperCase()
      if (matchType === 'NOT MATCHED') {
        return `Not Match (${matchType})`
      }
      if (matchType.startsWith('MATCH_RX')) {
        return `Matched With Name (${matchType})`
      }
      if (matchType.startsWith('MATCH_NN_RX')) {
        return `Matched Without (${matchType})`
      }
      return matchType
    } else {
      return 'Match in V1'
    }
  }
}
