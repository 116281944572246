
































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import throttle from "lodash.throttle";
import {Socket} from "vue-socket.io-extended";
import {Country} from "@/store/types";

@Component
export default class MemberFinder extends Vue {
  exactMatch = true


  dialog = false
  loading = false
  name = ''
  members: Array<any> = []
  header = [
    {text: 'name', value: 'MemberEmailAddress'},
    {text: 'email', value: 'MemberFullname'}
  ]

  requestFetchDataThrottled: ReturnType<typeof throttle> = throttle(this.requestFetchData.bind(this), 500)

  requestFetchData(emailAddress: string) {
    this.$socket.client.emit('users_finder', {emailAddress, exactMatch: this.exactMatch})
  }

  @Watch('exactMatch')
  @Watch('name')
  onNameChange() {
    if (this.name.length > 3) {
      this.loading = true
      this.requestFetchDataThrottled(this.name)
    } else {
      this.members = []
    }
  }

  @Socket('users_finder')
  onHorsesChange(items: {
    result: Array<any>;
    name: string;
  }) {
    if (items.name === this.name) {
      this.loading = false
      this.members = items.result
    }
  }

  select(item: number) {
    this.$emit('select', item)
    this.$emit('input', false)
  }

  @Watch('value')
  onValueChange() {
    this.dialog = this.value
  }

  get countries() {
    return this.$store.getters.countries
  }

  getCountryName(countryID: number) {
    return this.countries.find((c: Country) => c.CountryID === countryID)?.CountryCode || '-'
  }

  @Prop()
  value!: boolean
}
