




































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {
  DynamicSummaryClientManifest,
  DynamicSummaryColumnsSearchType,
  DynamicSummaryDataRequest,
  DynamicSummaryDataSearchRequest
} from '@/store/types'
import TableView from '@/components/DynamicSummary/TableView.vue'
import {DataOptions} from 'vuetify'
import SearchView from '@/components/DynamicSummary/SearchView.vue'
import {Socket} from 'vue-socket.io-extended'
import ResizeObserver from 'resize-observer-polyfill'
import CalendarView from "@/components/DynamicSummary/CalendarView.vue";

@Component({
  components: {CalendarView, SearchView, TableView}
})
export default class DynamicSummaryPage extends Vue {
  @Prop({required: true, type: String})
  serviceName!: string

  @Prop({default: false, type: Boolean})
  hideSearch!: string

  @Prop()
  staticSearchParameters!: Array<DynamicSummaryDataSearchRequest>

  manifestLoading = true
  dataLoading = false
  userData = Math.random()

  manifest!: DynamicSummaryClientManifest
  items: Array<unknown> = []
  itemsCount = 0
  tableOptions!: DataOptions
  searchRequests: Array<DynamicSummaryDataSearchRequest> = []

  searchResizeObserver!: ResizeObserver
  searchHeight = 0

  submitRequest() {
    const request: DynamicSummaryDataRequest = {
      pagination: {
        size: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page
      },
      sort: this.tableOptions.sortBy.length > 0 ? {
        columnName: this.tableOptions.sortBy[0],
        isDesc: this.tableOptions.sortDesc[0]
      } : undefined,
      search: this.searchRequests
    }
    if (this.calenderViewId !== false) {
      const calenderManifest = this.manifest.calenders.find(i => i.id === this.calenderViewId)
      if (calenderManifest) {
        request.pagination.page = 1
        request.pagination.size = 1000
        request.sort = undefined;
        request.search = request.search.filter(item => item.columnName !== calenderManifest.startAtValue)
        request.search.push({
          columnName: calenderManifest.startAtValue,
          type: DynamicSummaryColumnsSearchType.date_range,
          value: [this.calenderStartAt, this.calenderEndAt].join('|')
        })
      }
    }
    this.dataLoading = true
    this.$socket.client.emit('dynamic_summary_view', {
      serviceName: this.serviceName,
      request,
      userData: this.userData,
      withCount: this.itemsCount === 0
    })
  }

  @Watch('staticSearchParameters')
  onStaticSearchItemChanges() {
    if (this.staticSearchParameters && this.staticSearchParameters.length > 0) {
      this.search(this.searchRequests)
    }
  }

  created() {
    this.setSearchRequest([])
  }

  mounted() {
    this.$socket.client.emit('dynamic_summary_manifest', {serviceName: this.serviceName})
    if (!this.hideSearch) {
      this.searchResizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0]
        const {height} = entry.contentRect
        this.searchHeight = height
      })
    }
  }

  beforeDestroy() {
    this.searchResizeObserver.unobserve((this.$refs.searchElement as SearchView).$el)
  }

  tableOptionsChanged(options: DataOptions) {
    this.tableOptions = options
    this.submitRequest()
  }

  search(requests: Array<DynamicSummaryDataSearchRequest>) {
    this.itemsCount = 0
    this.setSearchRequest(requests)
    this.submitRequest()
  }

  setSearchRequest(requests: Array<DynamicSummaryDataSearchRequest>) {
    if (this.staticSearchParameters) {
      this.searchRequests = requests.concat(this.staticSearchParameters)
    } else {
      this.searchRequests = requests
    }
    this.searchRequests = this.searchRequests.filter((item, index, arr) => arr.findIndex(r => r.columnName === item.columnName) === index)

  }

  exportData() {
    const request: DynamicSummaryDataRequest = {
      pagination: {
        size: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page
      },
      sort: this.tableOptions.sortBy.length > 0 ? {
        columnName: this.tableOptions.sortBy[0],
        isDesc: this.tableOptions.sortDesc[0]
      } : undefined,
      search: this.searchRequests
    }
    this.dataLoading = true
    this.$socket.client.emit('dynamic_summary_export', {
      serviceName: this.serviceName,
      request,
      userData: this.userData
    })
  }

  @Socket('dynamic_summary_manifest')
  receiveManifest(response: { manifest: DynamicSummaryClientManifest; serviceName: string }) {
    if (this.serviceName === response.serviceName) {
      this.manifest = response.manifest
      this.manifestLoading = false
      setTimeout(() => {
        this.searchResizeObserver.observe((this.$refs.searchElement as SearchView).$el)
        const res = (this.$refs.searchElement as SearchView).$el.getClientRects()
        this.searchHeight = res[0].height
      })
    }
  }

  @Socket('dynamic_summary_view')
  receiveViewData(response: {
    data: Array<unknown>;
    serviceName: string;
    userData: number;
    count: number;
  }) {
    if (this.serviceName === response.serviceName && this.userData === response.userData) {
      this.items = response.data
      if (response.count > 0) {
        this.itemsCount = response.count
      }
      this.dataLoading = false
    }
  }

  @Socket('dynamic_summary_export')
  receiveExport(response: {
    data: string;
    serviceName: string;
    userData: number;
    count: number;
  }) {
    if (this.serviceName === response.serviceName && this.userData === response.userData) {
      const {data} = response
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/tab-separated-values;charset=utf-8,' + encodeURIComponent(data))
      element.setAttribute('download', `${this.serviceName}-${new Date()}.tsv`)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
      this.dataLoading = false
    }
  }

  calenderViewId: false | number = false

  calenderStartAt = ''
  calenderEndAt = ''

  setCalenderView(value: number | false) {
    this.calenderViewId = value
  }

  calenderOptionChanged([start, end]: Array<string>) {
    this.calenderStartAt = start
    this.calenderEndAt = end
    this.submitRequest()
  }
}
