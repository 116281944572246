/* eslint-disable @typescript-eslint/camelcase */

export interface FarmSummaryResource {
  FarmID: number;
  FarmName: string;
  FarmCountryID?: number;
  FarmCountryName?: string;
  FarmStateId?: number;
  FarmStateName?: string;
  StallionCount?: number;
  SponsoredStallion?: number;
  FarmMemberCount?: number;
}

export interface HorseColor {
  colourCode: string| null;
  colourDominancy: string;
  colourID: number;
  colourName: string;
  createdAt: string;
}

export interface FarmDetailsResource {
  FarmID: number; //
  FarmName: string; //
  FarmCountryID?: number; //
  FarmCountryName?: string; //
  FarmStateID?: number; //
  FarmStateName?: string; //
  FarmAddress?: string;
  FarmSuburb?: string;
  FarmPostcode?: string;
  FarmPhone?: string; //
  FarmWebsite?: string; //
  FarmImage?: string; //
  FarmURLID?: number; //
  FarmURL?: string; //
  FarmOrganisationID?: number; //
  createdAt?: string | Date;
  StallionCount?: number; //
  SponsoredStallion?: number; //
  FarmMemberCount?: number; //
}

export function locationCleaner (state?: string, country?: string) {
  if (state && country) {
    return `${state}, ${country}`;
  } else if (state) {
    return state;
  } else if (country) {
    return country;
  } else {
    return '-';
  }
}

export interface StallionDetailsResource {
  StallionID: number;
  FarmID: number;
  FarmName: string;
  StallionHorseID: number;
  StallionName: string;
  StallionYoB: null;
  StallionCoBID: null;
  StallionCoB: null;
  StallionSire: null;
  StallionDam: null;
  StallionURLID: null | number;
  StallionURL: null | string;
  StallionImage: null;
  StallionFee: null;
  StallionFeeCurrency: null;
  StallionFeeStatus: null;
  StallionYearToStud: null;
  StallionYearToRetired: null;
  StallionDateStart: null;
  StallionDateEnd: null;
  StallionPromotion20_20: null;
  StallionPromotionPerfectMatch: null;
  IsStallionSponsored: null;
  StallionCountryID: null;
  StallionCountryName: null;
  StallionStateID: null;
  StallionState: null;
  StallionAutoRenew: boolean;
  IsStallionActive: boolean;
  StallionUpdatedAt: string | Date;
  StallionCreatedAt: string | Date;
}

export interface StallionSummaryResource {
  StallionID: number;
  FarmID?: number;
  FarmName?: string;
  StallionHorseID?: number;
  StallionName: string;
  StallionCoB?: string;
  StallionSire?: string;
  StallionDam?: string;
  StallionFee?: number;
  StallionFeeCurrency?: string;
  StallionFeeStatus?: string;
  StallionYearToStud?: number;
  IsStallionSponsored?: boolean;
  StallionCountryID?: number;
  StallionCountryName?: string;
  StallionStateID?: number;
  StallionState?: string;
}

export interface Country {
  CountryID: number;
  CountryCode: string;
  CountryName: string;
  currency: string;
  isDisplay: boolean;
}

export interface State {
  StateID: number;
  StateName: string;
  CountryID: number;
  CountryName: string;
}

export interface FarmMemberResource {
  farmID: number;
  MemberID: number;
  FarmMemberID: number;
  FarmMemberFullname: string;
  FarmMemberEmail: string;
  isAdmin: boolean;
}

export function copyToClipboard (text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
}

export interface HorseSummaryResource {
  HorseID: number;
  HorseName: string;
  HorseSex: 'F' | 'M';
  HorseYoB: number;
  HorseCoB: string;
  HorseSireName: string;
  HorseSireYoB: string;
  HorseSireCoB: string;
  HorseDamName: string;
  HorseDamYoB: string;
  HorseDamCoB: string;
  HorseRunCount: number | null;
  HorseWinCount: number | null;
  HorseFamilyTrackingCount: number | null;
  HorseDependencyCount: number | null;
  HorseCreatedAt: Date | string;
  HorseFrom: string;
  HorseVerifiedBy: string;
  HorseSoundexCode: string;
  HorseNameKey: string;
  HorseCoBID: number;
  HorseSireID: number;
  HorseDamID: number;
}

export interface HorseFilterResource {
  horseName: string | null;
  exactMatch: boolean;
  country: Country | null;
  state: boolean | null;
  from: string | null;
  to: string | null;
  horseFrom: string | null;
  yobMin: number | null;
  yobMax: number | null;
  sireId: number | null;
  damId: number | null;
  thoroughbred: string | null;
  eligible: string | null;
  sireStatus: string | null;
  damStatus: string | null;
  runner: string | null;
  stakesWinners: string | null;
  prizeMin: number | null;
  prizeMax: number | null;
  prizeCurrency: string | null;
  options: {
    unverified: boolean;
    familyTracking: boolean;
    stallionMatch: boolean;
    horseInformation: boolean;
    missingCOB: boolean;
    missingYOF: boolean;
  };
}

export interface HorseDetailResource {
  HorseID: number;
  HorseName: string;
  HorseCoBID: number;
  HorseCoBCode: string;
  HorseYoB: string;
  HorseSex: 'F' | 'M';
  HorseColour: string;
  HorseColourID: number;
  HorseSireID: number;
  HorseSireName: string;
  HorseDamID: number;
  HorseDamName: string;
  HorseG1Count: number;
  HorseG2Count: number;
  HorseG3Count: number;
  HorseG4Count: number;
  HorseRunCount: number;
  HorseWinCount: number;
  HorseComments: string;
  HorseSalePrice: string;
  HorseSaleCurrency: string;
  HorsePrizeMoney: string;
  HorsePrizeMoneyCurrency: string;
  HorseTotalWins: number;
  HorseTotalStarts: number;
  HorseAccuracy: number;
  HorseFrom: string;
  HorseCreatedAt: string;
  HorseEnteredBy: string;
  HorseVerifiedAt: string;
  HorseVerifiedBy: string;
  HorseUpdatedBy: string;
  HorseFamilyTrackingCount: number;
  HorseDependencyCount: number;
  HorseIsGelding: string;
  HorseIsProtected: boolean;
  //

  HorseIsThoroughbred: boolean | null;
  HorseIsEligible: boolean | null;


  HorseNameKey: string;
  HorseSoundexCode: string;
}

export interface PedigreeRowResource {
  HorsePosition: string;
  HorseID: number;
  HorseName: string;
  HorseCoBCode: string;
  HorseYob: string;
}

export interface PedigreeRowResourceNew {
  HorsePosition: string;
  HorseID: number;
  HorseName: string;
  HorseCoBCode: string;
  HorseSex: 'M' | 'F';
  HorseVerifiedBy: string | null;
  HorseVerifiedTime: string | null;
  HorseYob: string;
  HorseColor?: string | null;
  HorseColorID?: number | null;
  HorseIsProtected?: boolean;
}


export type ObjectOf<T> = {
  [key: string]: T;
}

export type  PedigreeResource = ObjectOf<PedigreeRowResource | null>

export interface HorseResource {
  horseID?: number;
  horseName: string;
  yob: string;
  nationality: number;
  colour?: string;
  colourId: number;
  salePrice: string;
  saleCurrency: string;
  prizeMoney: string;
  prizeCurrency: string;
  sex: string;
  isEligible: boolean;
  isThoroughbred: boolean | null;
  gelding: string;
  sireId: number;
  damId: number;
  horseFrom?: string;
  isProtected?: boolean;
}

export function horseDetailToHorseResource (horse: HorseDetailResource, colors?: Array<HorseColor>): HorseResource {
  return {
    horseName: horse.HorseName,
    yob: horse.HorseYoB,
    nationality: horse.HorseCoBID,
    colour: colors?.find(c => c.colourID === horse.HorseColourID)?.colourName,
    colourId: horse.HorseColourID,
    salePrice: horse.HorseSalePrice,
    saleCurrency: horse.HorseSaleCurrency,
    prizeMoney: horse.HorsePrizeMoney,
    prizeCurrency: horse.HorsePrizeMoneyCurrency,
    sex: horse.HorseSex || '',
    isEligible: horse.HorseIsEligible || false,
    isThoroughbred: horse.HorseIsThoroughbred,
    gelding: horse.HorseIsGelding,
    sireId: horse.HorseSireID,
    damId: horse.HorseDamID,
    horseFrom: horse.HorseFrom,
    isProtected: horse.HorseIsProtected,
  };
}

export interface HorseFinderMiniResource {
  HorseID: number;
  HorseNationality: number;
  HorseYearOfBirth: string;
  HorseName: string;
}

export interface SalesTypeResource {
  salesTypeID: number;
  name: string;
  description: string;
  createdAt: string;
}

export interface SalesCompanyResource {
  SalesCompanyID: number;
  SalesCompanyName: string;
  SalesCompanyAddress: string;
  SalesCompanyWebsite: string;
  SalesCompanyCreatedAt: string;
  SalesCompanyCountryID: number;
  SalesCompanyCountryName: string;
}

export interface SalesInfoResource {
  SalesInfoID: number;
  SalesInfoName: string;
  SalesInfoDescription: string;
  SalesInfoCreatedAt: string;
  SalesInfoCountryID: number;
  SalesInfoCountryName: string;
  SalesInfoTypeID: number;
  SalesInfoTypeName: string;
  SalesInfoTypeDescription: string;
}

export interface SalesDetailResource {
  SalesID: number;
  SalesName: string;
  SalesCode: string;
  SalesStartDate: string;
  SalesIsOnlineSales: boolean;
  SalesIsPublic: boolean;
  SalesIsHIP: boolean;
  SalesCreatedAt: string;
  SalesCountryID: number;
  SalesCountryName: string;

  SalesInfoID: number;
  SalesInfoName: string;
  SalesInfoDescription: string;
  SalesInfoCreatedAt: string;
  SalesInfoCountryID: number;
  SalesInfoCountryName: string;

  SalesInfoTypeID: number;
  SalesInfoTypeName: string;
  SalesInfoTypeDescription: string;
  SalesInfoTypeCreatedAt: string;

  SalesCompanyID: number;
  SalesCompanyName: string;
  SalesCompanyAddress: string;
  SalesCompanyWebsite: string;
  SalesCompanyCreatedAt: string;

  SalesCompanyCountryID: number;
  SalesCompanyCountryName: string;

  SalesFileURL: string | null;
  SalesFileURLSDX: string | null;
}

export interface SalesSummaryResource {
  SalesID: number;
  SalesName: string;
  SalesCode: string;
  SalesStartDate: string;
  SalesIsOnlineSales: boolean;
  SalesIsPublic: boolean;
  SalesIsHIP: boolean;
  SalesCreatedAt: string;
  SalesCountryID: number;
  SalesCountryName: string;

  SalesInfoID: number;
  SalesInfoName: string;

  SalesInfoTypeID: number;
  SalesInfoTypeName: string;

  SalesCompanyID: number;
  SalesCompanyName: string;
  NotVerifiedLotCounts: number;
}

export interface HorseFinderResource {
  DamId: number;
  DamName: string;
  DamNationality: number;
  DamYearOfBirth: string;
  HorseG1: number;
  HorseG2: number;
  HorseG3: number;
  HorseG4: number;
  HorseID: number;
  HorseName: string;
  HorseNameKey: string;
  HorseNationality: number;
  HorseSex: string;
  HorseSoundexCode: string;
  HorseYearOfBirth: string;
  SireID: number;
  SireName: string;
  SireNationality: number;
  SireYearOfBirth: string;
}

export type PedigreeResourceTemporaryAble = PedigreeRowResourceNew & { isTmp?: boolean; isDefinedByUser: boolean }


// --------------------------------------------------------------------

export enum DynamicSummaryColumnsViewComputeType {
  auto = 'auto',
  date = 'date'
}

export enum DynamicSummaryColumnsSearchType {
  auto = 'auto',
  text_like_right = 'text_like_right',
  text_like_left = 'text_like_left',
  text_like_full = 'text_like_full',
  text_exact = 'text_exact',
  text_group_by = 'text_group_by',
  date_range = 'date_range',
  date_exact = 'date_exact',
  date_greater_then = 'date_greater_then',
  date_less_then = 'date_less_then',
  number_range = 'number_range',
  number_equal = 'number_equal',
  number_greater_than = 'number_greater_than',
  number_less_than = 'number_less_than',
  bit = 'bit',
  is_null = 'is_null',
}

export interface DynamicViewCalenderConfig {
  id: number;
  startAtValue: string;
  endAtValue?: string;
  colorValue?: string;
  value: string;
  searchTitle: string;
  searchOrder: number;
}

export enum DynamicSummaryDetailType {
  detail = 'detail',
  table = 'table',
}


export interface DynamicSummaryClientManifest {
  viewName: string;
  table: Array<{
    text: string;
    value: string;
    align: 'start';
    sortable: boolean;
    type: DynamicSummaryColumnsViewComputeType;
  }>;
  search: Array<{
    type: DynamicSummaryColumnsSearchType;
    label: string;
    placeholder: string;
    value: string;
    id: number;
    dependOn: number | null;
  }>;
  details: Array<{
    id: number;
    type: DynamicSummaryDetailType;
    lazy: boolean;
    title: string;
    mode: string;
    size: string;
    fK: string;
    columns: Array<{
      text: string;
      value: string;
      align: 'start';
      sortable: boolean;
    }>;
  }>;
  calenders: Array<DynamicViewCalenderConfig>;
}

export interface DynamicSummaryDataSearchRequest {
  type: DynamicSummaryColumnsSearchType;
  columnName: string;
  value: boolean | string | number;
}

export interface DynamicSummaryDataRequest {
  sort?: {
    columnName: string;
    isDesc: boolean;
  };
  pagination: {
    size: number;
    page: number;
  };
  search: Array<DynamicSummaryDataSearchRequest>;
}
