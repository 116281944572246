






import { Vue, Component } from 'vue-property-decorator';
import DynamicSummaryPage from '@/components/DynamicSummary/DynamicSummaryPage.vue';
@Component({
  components: { DynamicSummaryPage }
})
export default class MemberSubscriptionHistory extends Vue {

}
