




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DynamicSummaryClientManifest, DynamicSummaryColumnsViewComputeType } from '@/store/types'
import { DataOptions } from 'vuetify'
import moment from 'moment'

@Component
export default class TableView extends Vue {
  @Prop({ required: true, type: Object })
  public manifest!: DynamicSummaryClientManifest

  @Prop({ required: true, type: Array })
  public items!: Array<unknown>

  @Prop({ required: true, type: Number })
  public count!: number

  @Prop({ default: 0, type: Number })
  public searchComponentHeight!: number

  @Prop({ required: false, type: Boolean, default: true })
  public loading!: number

  page = 1
  itemsPerPage = 16
  isFirstRun = true


  optionsChanged (options: DataOptions) {
    this.$emit('update:options', options)
  }

  @Watch('searchComponentHeight', { immediate: true })
  calculatePageSize () {
    setTimeout(() => {
      this.itemsPerPage = Math.max(16, Math.floor((this.searchComponentHeight - 56 - 45) / 48))
    })
  }

  get tableHeaders () {
    return this.manifest.table
  }

  get pageCount () {
    return Math.ceil(this.count / this.itemsPerPage)
  }

  get itemsView () {
    return this.items.map((item: any) => {
      for (const header of this.tableHeaders) {
        if (item[header.value] && header.type === DynamicSummaryColumnsViewComputeType.date) {
          item[header.value] = moment(item[header.value]).format('DD.MM.YYYY')
        }
        if (!item[header.value] || (typeof item[header.value] === 'string' && item[header.value].length === 0)) {
          item[header.value] = '-'
        }
      }
      return item
    })
  }
}
