





































import {Component, Vue} from 'vue-property-decorator'
import {Socket} from 'vue-socket.io-extended'
import VueRecaptcha from 'vue-recaptcha'
import {User} from "@sentry/vue";

@Component({
  components: {VueRecaptcha}
})
export default class LoginPage extends Vue {
  isFormOk = true
  emailAddress = ''
  password = ''
  recaptchaToken = ''
  emailRules = [
    (v: string) => !!v || 'Email is required',
    (v: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Use valid email address'
  ]
  passwordRule = [
    (v: string) => !!v || 'Password is required',
  ]
  loading = false
  errors = {
    formValidation: false,
    wrongCredentials: false
  }

  onConnected(data: { fullName: string }) {
    this.loading = false
    this.$store.commit('SET_AUTHENTICATED_USER', data)
    this.$store.dispatch('countryIndex')
    this.$store.dispatch('colorsIndex')
    setTimeout(() => {
      this.$emit('done')
    }, 100)
  }

  async login() {
    if (this.isFormOk && this.recaptchaToken !== '') {
      this.errors.formValidation = false
      this.errors.wrongCredentials = false
      this.errors.wrongCredentials = false

      this.loading = true
      this.doLogin()
    } else {
      this.errors.formValidation = true
    }
  }

  created() {
    const rt = localStorage.getItem('refreshToken')
    const at = localStorage.getItem('accessToken')
    if (rt && at) {
      this.loading = true
      this.doRefresh(at, rt)
    }
  }

  doLogin() {
    this.$socket.client.emit('login', {
      username: this.emailAddress,
      password: this.password,
      recaptchaToken: this.recaptchaToken,
      mode: 'safeMode'
    })
  }

  @Socket('login')
  onLoginResponse(res: { user: any; refreshToken: string; token: string; deviceId: number }) {
    localStorage.setItem('refreshToken', res.refreshToken)
    localStorage.setItem('accessToken', res.token)
    this.onConnected(res.user)
  }

  doRefresh(accessToken: string, refreshToken: string) {
    this.$socket.client.emit('login:refresh', {
      accessToken, refreshToken
    })
  }

  @Socket('login:failed')
  onLoginError() {
    this.errors.wrongCredentials = true
    this.loading = false
  }

  validate(recaptchaToken: string) {
    this.recaptchaToken = recaptchaToken
  }
}
