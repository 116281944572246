



































import {Vue, Component, Watch} from "vue-property-decorator"

@Component
export default class SalesCatalogItem extends Vue {
  saleId = 0

  @Watch('$route')
  onRouteChange() {
    this.saleId = Number(this.$route.params.id)
  }

  created() {
    this.onRouteChange()
  }

  mounted() {
    if (this.$store.getters.user.accessLevel < 2) {
      this.$router.push({name: 'errors.accessDenied'})
    }
  }
}
