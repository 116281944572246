








































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {copyToClipboard, FarmDetailsResource} from '@/store/types';
import {Socket} from 'vue-socket.io-extended';
import ImageUploader from '@/components/ImageUploader.vue';

@Component({
  components: {ImageUploader}
})
export default class FarmUpdate extends Vue {
  farmId = 0;
  detailLoading = false;
  modelUpdated = false;
  editMode = false;
  valid = false;
  errorAlert = false
  requiredRule = [(v: string) => !!v || 'this field is required'];

  details: Partial<FarmDetailsResource> = {
    FarmName: '',
    FarmPhone: '',
    FarmWebsite: '',
    FarmURL: '',
    FarmCountryName: '',
    FarmStateName: '',
    FarmAddress: '',
    FarmPostcode: ''
  };

  goToStallions() {
    this.$router.push({
      name: 'StallionMatch.stallions',
      query: {farmId: this.details.FarmID?.toString() || '', farmName: this.details.FarmName}
    });
  }

  @Watch('$route')
  onRouteChange() {
    if (this.$route.name === 'StallionMatch.farms.new') {
      this.editMode = false;
    } else {
      this.editMode = true;
      this.farmId = Number(this.$route.params.id);
      this.detailLoading = true;
      this.$socket.client.emit('farms_details', {farmId: this.farmId});
    }
  }


  @Socket('farms_update')
  @Socket('farms_new')
  farmUpdated(item: FarmDetailsResource) {
    this.modelUpdated = true;
    this.farmDetailLoaded(item);
    if (!this.editMode) {
      setTimeout(() => {
        this.$router.push({name: 'StallionMatch.farms.update', params: {id: item.FarmID.toString()}});
      }, 1500);
    }
  }

  @Socket('farms_details')
  farmDetailLoaded(item: FarmDetailsResource) {
    this.details = item;
    this.detailLoading = false;
    if (this.details.FarmImage) {
      this.$socket.client.emit('farms_image', {farmId: this.details.FarmID});
    }
    this.loadStates();
  }

  imgBuffer: ArrayBuffer | null = null;
  imgUploadLoading = false;
  farmImage = '';

  @Socket('farms_image')
  onImageReceive(buf: ArrayBuffer | null) {
    this.imgUploadLoading = false;
    if (buf) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.farmImage = e.target?.result as string;
      };
      const blob = new Blob([buf], {type: 'image/png'});
      reader.readAsDataURL(blob);
    }
  }

  uploadImage(buffer: ArrayBuffer) {
    this.imgBuffer = buffer;
    if (this.editMode) {
      this.doUploadImage();
    }
  }

  doUploadImage() {
    this.imgUploadLoading = true;
    this.$socket.client.emit('farms_image_update', {farmId: this.details.FarmID, image: this.imgBuffer});
  }

  mounted() {
    this.onRouteChange();
  }

  loadStates() {
    if (this.details.FarmCountryID) {
      this.$store.dispatch('stateIndex', this.details.FarmCountryID);
    }
  }

  update() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate()
    if (!this.valid) {
      this.errorAlert = true
      return false
    }
    const req = {
      farmId: this.farmId,
      name: this.details.FarmName,
      countryID: this.details.FarmCountryID,
      stateID: this.details.FarmStateID,
      address: this.details.FarmAddress,
      suburb: null,
      postcode: this.details.FarmPostcode,
      phone: this.details.FarmPhone,
      website: this.details.FarmWebsite,
      image: null,
      organisationID: null,
    };
    if (this.editMode) {
      this.$socket.client.emit('farms_update', req);
    } else {
      this.$socket.client.emit('farms_new', req);
    }
    if (this.details.FarmName) {
      this.details.FarmURL = `G1StallionMatch - ${this.details.FarmName.replace(/ /g, '')}.htm`
      this.$socket.client.emit('stallion_url_update', {farmId: this.details.FarmID, url: this.details.FarmURL})
    }
    this.detailLoading = true;
  }

  get countries() {
    return this.$store.getters.countries;
  }

  get states() {
    return this.$store.getters.states;
  }

  get loadings() {
    return this.$store.getters.loading;
  }

  copyDoneSnackbar = false;

  copyURLToClipboard() {
    copyToClipboard(encodeURI('https://g1goldmine.com/g1g/ui/stallionmatch-result?for=' + this.details.FarmURL));
    this.copyDoneSnackbar = true;
  }
}
