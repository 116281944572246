






























































































































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {HorseDetailResource, horseDetailToHorseResource, HorseFinderResource} from '@/store/types';
import {Socket} from 'vue-socket.io-extended';
import HorseFinder from '@/components/HorseFinder.vue';

@Component({
  components: {HorseFinder}
})
export default class HorseDetailUpdate extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  full!: boolean;

  @Prop({default: false, type: Boolean})
  detailed!: boolean;

  @Prop({
    default: false,
    type: Boolean
  })
  drawerMode!: boolean;

  @Prop({
    required: true,
    type: Number
  })
  value!: number;

  horseDetailHash = 0;
  drawerModeRefresh = true;

  get countries() {
    return this.$store.getters.countries;
  }

  get loadings() {
    return this.$store.getters.loading;
  }

  @Watch('value')
  onValueChange() {
    if (this.value >= 0) {
      this.loading = true;
      this.horseDetailHash = Math.random();
      this.$socket.client.emit('horses_details', {horseId: this.value, userData: this.horseDetailHash});
    }
  }


  oldName = '';
  details: HorseDetailResource = {
    HorseID: -1,
    HorseName: '',
    HorseCoBID: 0,
    HorseCoBCode: '',
    HorseYoB: '',
    HorseSex: 'F',
    HorseColour: '',
    HorseColourID: 0,
    HorseSireID: 0,
    HorseSireName: '',
    HorseDamID: 0,
    HorseDamName: '',
    HorseG1Count: 0,
    HorseG2Count: 0,
    HorseG3Count: 0,
    HorseG4Count: 0,
    HorseCreatedAt: '',
    HorseEnteredBy: '',
    HorseVerifiedAt: '',
    HorseVerifiedBy: '',
    HorseFrom: '',
    HorseIsGelding: 'N',
    HorseNameKey: '',
    HorseSoundexCode: '',
    HorseSalePrice: '',
    HorseSaleCurrency: '',
    HorseAccuracy: 0,
    HorsePrizeMoney: '',
    HorsePrizeMoneyCurrency: '',
    HorseTotalWins: 0,
    HorseTotalStarts: 0,
    HorseIsThoroughbred: true,
    HorseUpdatedBy: '',
    HorseIsEligible: null,
    HorseIsProtected: false,
    HorseRunCount: 0,
    HorseWinCount: 0,
    HorseFamilyTrackingCount: 0,
    HorseDependencyCount: 0,
    HorseComments: '',
  };

  requiredRule = [(v: string | null) => (!!v && v.length > 0) || 'this filed is required'];
  ActiveRule = [(v: number | null) => (v && v > 0 || 'this filed is required')];


  loading = false;
  noInformation = true;

  @Socket('horses_details')
  onDetailsLoaded(detail: { result: HorseDetailResource; userData?: number }) {
    if (detail.userData) {
      if (detail.userData !== this.horseDetailHash) return;
    }
    this.details = detail.result;
    this.details.HorseIsGelding = this.details.HorseIsGelding === 'Y' ? 'Y' : 'N'
    this.oldName = this.details.HorseName;
    this.loading = false;
    this.onSexChange()
  }

  @Watch('details')
  onDetailChange() {
    // const sex = this.details.HorseSex;
    // this.$nextTick(() => {
    //   this.details.HorseSex = this.details.HorseSex === 'M' ? 'F' : 'M';
    //   setTimeout(() => {
    //     this.details.HorseSex = this.details.HorseSex === 'M' ? 'F' : 'M';
    //   });
    // });
  }

  created() {
    this.onValueChange();
    this.onSexChange();
    if (this.drawerMode) {
      // this.drawerModeRefresh = false
      // setTimeout(() => {
      //   this.drawerModeRefresh = true
      // }, 5000)
    }
  }

  get horseColours() {
    return this.$store.getters.colors;
  }

  get colorLoading() {
    return this.$store.getters.loading.colors
  }

  nullToZero(item: null | number) {
    return item === null ? 0 : item;
  }

  nullToUnknown(item: null | boolean | string) {
    return item === null ? '-' : item;
  }

  formState = false;

  renameHorseConfirm = false

  cancelRename() {
    this.details.HorseName = this.oldName;
    this.renameHorseConfirm = false
  }

  doRename() {
    this.renameHorseConfirm = false
    this.loading = true;
    this.horseDetailHash = Math.random();
    const resource = horseDetailToHorseResource(this.details, this.horseColours);
    resource.horseID = this.value;
    this.$socket.client.emit('horses_update', {...resource, userData: this.horseDetailHash});
  }

  saveHorse() {
    if (this.details.HorseYoB.toString().length !== 4) {
      alert('YoB Validation: Exact 4 digits')
      return
    }
    if (this.details.HorseYoB !== '0000' && Number(this.details.HorseYoB) <= 1000) {
      alert('YoB Validation: Greater than 1000')
      return
    }
    (this.$refs.form as any).validate();
    setTimeout(() => {
      if (this.formState) {
        if (this.drawerMode) {
          this.$emit('done', this.details);
        } else {
          if (this.oldName !== this.details.HorseName) {
            this.renameHorseConfirm = true
          } else {
            this.loading = true;
            this.horseDetailHash = Math.random();
            const resource = horseDetailToHorseResource(this.details);
            resource.horseID = this.value;
            this.$socket.client.emit('horses_update', {...resource, userData: this.horseDetailHash});
          }
        }
      }
    });
  }

  mergeHorse() {
    this.horseDialog = true;
  }

  deleteHorseConfirm = false

  deleteHorse() {
    this.deleteHorseConfirm = true
  }

  doDeleteHorse() {
    this.loading = true;
    this.$socket.client.emit('horses_delete', {
      horseID: this.details.HorseID
    });
  }

  cancelDeleteHorse() {
    this.deleteHorseConfirm = false
  }

  @Socket('horses_delete')
  onHorseDeleted(res: string | true) {
    if (res !== true) {
      alert(res);
    } else {
      this.$emit('deleted');
    }
    this.loading = false;
  }

  successSnackBar = false;

  @Socket('horses_update')
  entitySaved(detail: { result: HorseDetailResource; userData?: number }) {
    if (detail.userData) {
      if (detail.userData !== this.horseDetailHash) return;
    }
    this.successSnackBar = true;
    if (this.value <= 0) {
      setTimeout(() => {
        this.$router.push({name: 'Data.horse.update', params: {id: detail.result.HorseID.toString()}});
      }, 1500);
    }
    this.onDetailsLoaded(detail);
    setTimeout(() => {
      this.$router.back();
    }, 3000);
  }

  reset(details?: HorseDetailResource) {
    (this.$refs.form as any).reset();
    if (details) {
      this.details = details;
    } else {
      this.details = {
        HorseID: -1,
        HorseName: '',
        HorseCoBID: 0,
        HorseCoBCode: '',
        HorseYoB: '',
        HorseSex: 'M',
        HorseColour: '',
        HorseColourID: 0,
        HorseSireID: 0,
        HorseSireName: '',
        HorseDamID: 0,
        HorseDamName: '',
        HorseG1Count: 0,
        HorseG2Count: 0,
        HorseG3Count: 0,
        HorseG4Count: 0,
        HorseCreatedAt: '',
        HorseEnteredBy: '',
        HorseVerifiedAt: '',
        HorseVerifiedBy: '',
        HorseFrom: '',
        HorseIsGelding: 'N',
        HorseNameKey: '',
        HorseSoundexCode: '',
        HorseSalePrice: '',
        HorseSaleCurrency: '',
        HorseAccuracy: 0,
        HorsePrizeMoney: '',
        HorsePrizeMoneyCurrency: '',
        HorseTotalWins: 0,
        HorseTotalStarts: 0,
        HorseIsThoroughbred: true,
        HorseUpdatedBy: '',
        HorseIsEligible: null,
        HorseIsProtected: false,
        HorseRunCount: 0,
        HorseWinCount: 0,
        HorseFamilyTrackingCount: 0,
        HorseDependencyCount: 0,
        HorseComments: '',
      };
    }
  }

  get user() {
    return this.$store.getters.user;
  }

  get isAdmin() {
    return this.user.accessLevel > 3;
  }

  horseDialog = false;

  selectedHorseForMerge: false | HorseFinderResource = false
  mergeHorseConfirm = false

  onSelectedHorse(horse: HorseFinderResource) {
    this.selectedHorseForMerge = horse
    this.mergeHorseConfirm = true
  }

  doMergeHorse() {
    if (this.selectedHorseForMerge) {
      this.$socket.client.emit('horses_merge', {
        horseID: this.details.HorseID,
        targetHorseId: this.selectedHorseForMerge.HorseID
      });
      this.loading = true;
      this.mergeHorseConfirm = false
    }
  }

  cancelMergeHorse() {
    this.selectedHorseForMerge = false
    this.mergeHorseConfirm = false
  }

  mergedSuccess = false

  @Socket('horses_merge')
  onHorseMerged(res: true | string) {
    if (res !== true) {
      alert(res);
    }
    this.loading = false;
    this.mergedSuccess = true
  }

  disableGelding = false

  // @Watch('details', {deep: true})
  onSexChange() {
    // if (this.details.HorseID !== 0) {
    if (this.details.HorseSex === 'F') {
      this.details.HorseIsGelding = 'N'
      this.disableGelding = true
    } else {
      this.disableGelding = false
    }
    // }
  }

  get hasAccessToProtectedHorse() {
    const roles: Array<{ subject: string; action: string }> = this.$store.getters.roles
    return roles.findIndex(r => r.action === 'updateProtectedMode' && r.subject === 'horse') > -1
  }

  get hasAccessToRenameHorse() {
    const roles: Array<{ subject: string; action: string }> = this.$store.getters.roles
    return roles.findIndex(r => r.action === 'rename' && r.subject === 'horse') > -1
  }

  updateProtectedMode(value: boolean) {
    if (this.hasAccessToProtectedHorse && !this.drawerMode) {
      this.$socket.client.emit('horses_update_protected_mode', {
        horseID: this.details.HorseID,
        value,
      })
    }
  }

  @Socket('horses_update_protected_mode')
  onHorseProtectedUpdate() {
    alert('Horse Protected Mode has been updated!')
  }

  get isEditable() {
    return !this.details.HorseIsProtected
  }
}
