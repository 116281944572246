




























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HorseDetailResource, horseDetailToHorseResource } from '@/store/types';
import { Socket } from 'vue-socket.io-extended';

@Component
export default class HorseDetailUpdateMini extends Vue {

  @Prop({
    default: false,
    type: Boolean
  })
  flat!: boolean;

  @Prop({
    required: true,
    type: Number
  })
  value!: number;
  loading = true;
  horseDetailHash = 0;

  @Watch('value')
  onValueChange () {
    this.loading = true;
    if (this.value >= 0) {
      this.horseDetailHash = Math.random();
      this.$socket.client.emit('horses_details', { horseId: this.value, userData: this.horseDetailHash });
    }
  }

  oldName = '';
  details: HorseDetailResource = {
    HorseID: 0,
    HorseName: '',
    HorseCoBID: 0,
    HorseCoBCode: '',
    HorseYoB: '',
    HorseSex: 'F',
    HorseColour: '',
    HorseColourID: 0,
    HorseSireID: 0,
    HorseSireName: '',
    HorseDamID: 0,
    HorseDamName: '',
    HorseG1Count: 0,
    HorseG2Count: 0,
    HorseG3Count: 0,
    HorseG4Count: 0,
    HorseCreatedAt: '',
    HorseEnteredBy: '',
    HorseVerifiedAt: '',
    HorseVerifiedBy: '',
    HorseFrom: '',
    HorseIsGelding: 'N',
    HorseNameKey: '',
    HorseSoundexCode: '',
    HorseSalePrice: '',
    HorseSaleCurrency: '',
    HorseAccuracy: 0,
    HorsePrizeMoney: '',
    HorsePrizeMoneyCurrency: '',
    HorseTotalWins: 0,
    HorseTotalStarts: 0,
    HorseIsThoroughbred: null,
    HorseUpdatedBy: '',
    HorseIsEligible: null,
    HorseRunCount: 0,
    HorseWinCount: 0,
    HorseFamilyTrackingCount: 0,
    HorseDependencyCount: 0,
    HorseComments: '',
    HorseIsProtected: false
  };

  successSnackBar = false;

  @Socket('horses_update')
  entitySaved (detail: { result: HorseDetailResource; userData?: number }) {
    if (detail.userData) {
      if (detail.userData !== this.horseDetailHash) return;
    }
    this.successSnackBar = true;
    this.onDetailsLoaded(detail);
    this.$emit('done');
  }

  @Socket('horses_details')
  onDetailsLoaded (detail: { result: HorseDetailResource; userData?: number }) {
    if (detail.userData) {
      if (detail.userData !== this.horseDetailHash) return;
    }
    this.details = detail.result;
    this.details.HorseIsGelding = this.details.HorseIsGelding === 'Y' ? 'Y' : 'N'
    this.oldName = this.details.HorseName;
    this.loading = false;
  }

  get horseColours () {
    return this.$store.getters.colors
  }

  get colorLoading () {
    return this.$store.getters.loading.colors
  }

  renameHorseConfirm = false
  update () {
    if (this.details.HorseYoB.toString().length !== 4) {
      alert('YoB Validation: Exact 4 digits')
      return
    }
    if (this.details.HorseYoB !== '0000' && Number(this.details.HorseYoB) <= 1000) {
      alert('YoB Validation: Greater than 1000')
      return
    }
    if (this.oldName !== this.details.HorseName) {
      this.renameHorseConfirm = true
    } else{
      this.loading = true;
      const resource = horseDetailToHorseResource(this.details, this.horseColours);
      resource.horseID = this.value;
      this.horseDetailHash = Math.random();
      this.$socket.client.emit('horses_update', { ...resource, userData: this.horseDetailHash });
    }
  }

  cancelRename() {
    this.details.HorseName = this.oldName;
    this.renameHorseConfirm = false
  }

  doRename() {
    this.renameHorseConfirm = false
    this.loading = true;
    this.horseDetailHash = Math.random();
    const resource = horseDetailToHorseResource(this.details);
    resource.horseID = this.value;
    this.$socket.client.emit('horses_update', {...resource, userData: this.horseDetailHash});
  }

  created () {
    this.onValueChange();
  }

  get user () {
    return this.$store.getters.user;
  }

  get isAdmin () {
    return this.user.accessLevel > 3;
  }

  get countries () {
    return this.$store.getters.countries;
  }

  get loadings () {
    return this.$store.getters.loading;
  }

  disableGelding = false

  @Watch('details.HorseSex')
  onSexChange() {
    if (this.details.HorseSex === 'F') {
      this.details.HorseIsGelding = 'N'
      this.disableGelding = true
    } else {
      this.disableGelding = false
    }
  }

  get hasAccessToProtectedHorse() {
    const roles: Array<{ subject: string; action: string }> = this.$store.getters.roles
    return roles.findIndex(r => r.action === 'updateProtectedMode' && r.subject === 'horse') > -1
  }

  get hasAccessToRenameHorse() {
    const roles: Array<{ subject: string; action: string }> = this.$store.getters.roles
    return roles.findIndex(r => r.action === 'rename' && r.subject === 'horse') > -1
  }

  updateProtectedMode(value: boolean) {
    if (this.hasAccessToProtectedHorse) {
      this.$socket.client.emit('horses_update_protected_mode', {
        horseID: this.details.HorseID,
        value,
      })
    }
  }

  @Socket('horses_update_protected_mode')
  onHorseProtectedUpdate() {
    this.$emit('update')
    // alert('Horse Protected Mode has been updated!')
  }

  get isEditable() {
    return !this.details.HorseIsProtected
  }
}
