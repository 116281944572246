
















































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { copyToClipboard, SalesDetailResource } from '@/store/types'
import { Socket } from 'vue-socket.io-extended'

@Component
export default class SalesCatalogDetails extends Vue {
  saleId = 0
  details: SalesDetailResource | null = null
  detailsLoading = false
  updateURLDialog = false
  newUrl: string | null = ''
  mode = 'normal'
  updateURLDialogLoading = false

  @Watch('$route')
  onRouteChange () {
    this.saleId = Number(this.$route.params.id)
    if (this.saleId > 0) {
      this.detailsLoading = true
      this.$socket.client.emit('sales_details', { salesId: this.saleId })
    }
  }

  @Socket('sales_details')
  onDetailLoaded (res: SalesDetailResource) {
    this.details = res
    this.detailsLoading = false
  }

  created () {
    this.onRouteChange()
  }

  openEditDialog (mode: string) {
    this.mode = mode
    this.newUrl = (mode === 'sdx' ? this.details?.SalesFileURLSDX : this.details?.SalesFileURL) || ''
    this.updateURLDialog = true
    this.updateURLDialogLoading = false
  }

  updateURL () {
    this.updateURLDialogLoading = true
    this.$socket.client.emit('sales_link_update', {
      saleId: this.saleId, link: this.newUrl, mode: this.mode
    })
  }

  @Socket('sales_link_update')
  onUrlUpdate() {
    this.updateURLDialogLoading = false
    setTimeout(() => {
      this.updateURLDialog = false
      if (this.details) {
        if (this.mode === 'sdx') {
          this.details.SalesFileURLSDX = this.newUrl
        } else {
          this.details.SalesFileURL = this.newUrl
        }
      }
    }, 50)
  }

  copyToClipboard (text: string) {
    copyToClipboard(text)
  }


  pdfServerLoading = false
  sendToPDFServer() {
    this.$socket.client.emit('sales_send_to_pdf', { saleId: this.saleId })
    this.pdfServerLoading = true
}

  @Socket('sales_send_to_pdf')
  handleSalesPDF(res: any) {
    this.pdfServerLoading = false
    window.location.href = `http://ec2-3-25-152-184.ap-southeast-2.compute.amazonaws.com/#/reports/60f4a4b87a7a2e0008efe2f4/order/${res._id}`
  }
}
