import Vue from 'vue'

export function EventHub(): void {
    const eventHub = new Vue()
    Object.defineProperties(eventHub, {
        on: {
            get: function () {
                return eventHub.$on
            }
        },
        once: {
            get: function () {
                return eventHub.$once
            }
        },
        emit: {
            get: function () {
                return eventHub.$emit
            }
        },
        off: {
            get: function () {
                return eventHub.$off
            }
        }
    })
    Object.defineProperty(Vue.prototype, '$eventHub', {
        get: function () {
            return eventHub
        }
    })
}

declare module 'vue/types/vue' {
    interface Vue {
        $eventHub: {
            on: (event: string | string[], callback: Function) => Vue;
            emit: (event: string, ...args: any[]) => Vue;
            off: (event?: string | string[], callback?: Function) => Vue;
            once: (event: string | string[], callback: Function) => Vue;
        };
    }
}