










































































































import {Component, Vue, Watch} from "vue-property-decorator"

@Component
export default class NewFarm extends Vue {
  dialog = false
  farmCreatedSnackbar = false
  farmAdminAssignedSnackbar = false
  closeWithoutAdminAssignedDialog = false
  step = 1
  farmId: number | null = null
  loading = false
  stateLoading = false
  userLoading = false

  close() {
    if (this.step === 3) {
      this.closeWithoutAdminAssignedDialog = true
    } else {
      this.dialog = false
    }
  }

  create() {
    this.loading = true
    setTimeout(() => {
      this.farmCreatedSnackbar = true
      this.loading = false
      this.step = 3
    }, 1000)
  }

  assign() {
    this.loading = true
    setTimeout(() => {
      this.farmAdminAssignedSnackbar = true
      this.loading = false
      this.dialog = false
    }, 1000)
  }

  loadStates() {
    this.stateLoading = true
  }

  reset() {
    this.step = 1
  }

  @Watch('dialog')
  onDialogChange(dialogValue?: boolean) {
    if (dialogValue === false) {
      setTimeout(this.reset.bind(this), 1000)
    }
  }

  image = ''

  loadFile(event: File) {
    this.image = URL.createObjectURL(event);
  }
}
