
































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Country, locationCleaner, StallionSummaryResource, State} from '@/store/types'
import {Socket} from 'vue-socket.io-extended'
import FarmFinder from '@/components/FarmFinder.vue'
import HorseDetail from '@/components/HorseDetail.vue'

@Component({
  components: {HorseDetail, FarmFinder}
})
export default class StallionMatchStallions extends Vue {
  locationCleaner(stallion: StallionSummaryResource) {
    return locationCleaner(stallion.StallionState, stallion.StallionCountryName)
  }

  get stallionStatus() {
    return [{
      name: 'Active',
      value: true
    }, {
      name: 'Inactive',
      value: false
    }]
  }

  get feeStatus() {
    return [
      'FOA',
      'TBA',
      'Firm',
      'PROV',
      'Est.',
      'PRIVATE',
    ]
  }

  // -------------------------------   start pagination  --------------------------------- //
  selectedItem = []
  stallionListHeader = [
    {
      text: 'Name'.toUpperCase(),
      value: 'StallionName',
      sortable: true
    },
    {
      text: 'Sire x Dam'.toUpperCase(),
      value: 'sire_x_dam',
      sortable: false
    },
    {
      text: 'Location'.toUpperCase(),
      value: 'location',
      sortable: true
    },
    {
      text: 'Service Fee'.toUpperCase(),
      value: 'stallion_fee',
      sortable: true
    },
    {
      text: 'Last Update'.toUpperCase(),
      value: 'last_update',
      sortable: true
    },
    {
      text: 'Farm'.toUpperCase(),
      value: 'FarmName',
      sortable: true
    },
    {
      text: 'Active'.toUpperCase(),
      value: 'IsStallionActive',
      sortable: true
    },
    {
      text: 'SPONSORED'.toUpperCase(),
      value: 'IsStallionSponsored',
      sortable: true
    }
    // {
    //   text: 'Actions'.toUpperCase(),
    //   value: 'actions'
    // },
  ]
  stallionSummaryPage = 1

  sortDesc: boolean | undefined = undefined
  sortableItem: string | undefined = undefined

  farmFinderDialog = false

  farmSelect(item: { FarmName: string; FarmID: number }) {
    this.farmPlaceHolder = item
    this.filters.farm = item.FarmID
  }

  filters: { [key: string]: string | null | number } = {
    name: '',
    farm: null,
    status: null,
    country: null,
    state: null,
    actives: null,
    currency: null,
    min: null,
    max: null,
    feeStatus: null
  }

  @Watch('stallionSummaryPage')
  stallionListPageHandler(page: number) {
    this.$store.dispatch('loadStallionSummary', {page: page - 1, pageSize: this.stallionSummary.perPage})
  }

  processFilters() {
    const query: { [key: string]: string | null | number } = {}
    const sorts = []
    if (this.sortableItem) {
      let key = ''
      switch (this.sortableItem) {
        case 'StallionName':
          key = 'StallionName'
          break
        case 'location':
          key = 'StallionState'
          break
        case 'stallion_fee':
          key = 'StallionFee'
          break
        case 'last_update':
          key = 'StallionUpdatedAt'
          break
        case 'FarmName':
          key = 'FarmName'
          break
        case 'IsStallionSponsored':
          key = 'IsStallionSponsored'
          break
        case 'IsStallionActive':
          key = 'IsStallionActive'
          break
      }
      if (key.length > 0) {
        sorts.push({[key]: this.sortDesc ? 'DESC' : 'ASC'})
      }
    }
    if (this.filters.name && this.filters.name.toString().length > 0) {
      query['StallionName'] = this.filters.name
    }
    if (this.filters.farm || 0 !== 0) {
      query['FarmID'] = Number(this.filters.farm)
    }
    if (this.filters.status !== null && typeof this.filters.status !== 'undefined') {
      query['IsStallionSponsored'] = this.filters.status
    }
    if (this.filters.actives !== null && typeof this.filters.actives !== 'undefined') {
      query['IsStallionActive'] = this.filters.actives
    }
    if (this.filters.country) {
      query['StallionCountryID'] = (this.filters.country as unknown as Country).CountryID
    }
    if (this.filters.state) {
      query['StallionStateID'] = (this.filters.state as unknown as State).StateID
    }
    if (this.filters.currency) {
      query['StallionFeeCurrency'] = this.filters.currency
    }
    if (this.filters.min !== null && !isNaN(Number(this.filters.min)) && this.filters.min.toString().length || 0 > 0) {
      query['min'] = this.filters.min
    }
    if (this.filters.max !== null && !isNaN(Number(this.filters.max)) && this.filters.max.toString().length || 0 > 0) {
      query['max'] = this.filters.max
    }
    return {query, sorts}
  }

  stallionListOptionsHandler() {
    const q: Record<string, string> = {
      data: JSON.stringify(this.filters)
    }
    if (this.filters.farm) {
      q.farmId = this.filters.farm.toString()
      q.farmName = this.farmPlaceHolder.FarmName
    }
    this.$router.push({
      path: this.$route.path, query: q
    })
    const {query, sorts} = this.processFilters()
    this.$store.dispatch('initStallionSummaryPagination', {query, sorts, pageSize: 18})
  }

  changeSort(items: Array<string>) {
    this.sortableItem = items[0]
    this.stallionListOptionsHandler()
  }

  changeSortMode(desc: Array<boolean>) {
    this.sortDesc = desc[0]
    this.stallionListOptionsHandler()
  }

  get stallionSummary() {
    return this.$store.getters.stallionSummary
  }

  get stallionSummaryPageLength() {
    return Math.ceil(this.stallionSummary.finalLength / 18)
  }

  // -------------------------------    end pagination   --------------------------------- //


  get loadings() {
    return this.$store.getters.loading
  }


  loadStates() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.$store.dispatch('stateIndex', this.filters.country?.CountryID)
  }


  get countries() {
    return this.$store.getters.countries
  }

  get states() {
    return this.$store.getters.states
  }

  get farmStatus() {
    return [{
      name: 'Active',
      value: true
    }, {
      name: 'Inactive',
      value: false
    }]
  }


  // -------------------------------   start delete stallion  --------------------------------- //
  stallionDeleteLoading = false
  stallionDeleteDialog = false
  selectedStallion = {StallionName: '', StallionID: 0}

  deleteStallion(item: StallionSummaryResource) {
    this.selectedStallion = item
    this.stallionDeleteDialog = true
    this.stallionDeleteLoading = false
  }

  deleteStallionForce() {
    this.stallionDeleteLoading = true
    this.$socket.client.emit('stallion_delete', {stallionID: this.selectedStallion.StallionID})
  }

  @Socket('stallion_delete')
  onStallionDeleted() {
    this.stallionDeleteLoading = false
    this.stallionDeleteDialog = false
  }

  // -------------------------------     end delete stallion   --------------------------------- //
  farmPlaceHolder: { FarmID: number; FarmName: string } = {FarmID: 0, FarmName: ''}

  @Watch('$route')
  onRouteChange() {
    if (this.$route.query.farmId) {
      this.filters.farm = this.$route.query.farmId.toString()
      this.farmPlaceHolder = {
        FarmID: Number(this.$route.query.farmId),
        FarmName: this.$route.query.farmName.toString() || ''
      }
    }
  }


  created() {
    if (this.$route.query.data) {
      this.filters = JSON.parse(this.$route.query.data as string)
    }
    if (this.$route.query.farmName) {
      this.farmPlaceHolder = {
        FarmID: Number(this.$route.query.farmId),
        FarmName: this.$route.query.farmName.toString() || ''
      }
    }
    this.onRouteChange()
    this.stallionListOptionsHandler()
  }

  // -------------------------------     export stallion      --------------------------------- //
  exportLoading = false

  requestExport() {
    if (this.stallionSummary.finalLength > 3000) {
      if (!confirm(`There is ${this.stallionSummary.finalLength} stallion in this request, are you sure you want to run this export ?`)) return
    }
    this.exportLoading = true
    const {query, sorts} = this.processFilters()
    this.$socket.client.emit('stallion_export', {where: query, sorts})
  }

  @Socket('stallion_export')
  receiveExport(data: string) {
    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/tab-separated-values;charset=utf-8,' + encodeURIComponent(data))
    element.setAttribute('download', `Stallions-${new Date()}.tsv`)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
    this.exportLoading = false
  }
}
