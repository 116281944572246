import { render, staticRenderFns } from "./NewHorse.vue?vue&type=template&id=00590456&scoped=true&"
import script from "./NewHorse.vue?vue&type=script&lang=ts&"
export * from "./NewHorse.vue?vue&type=script&lang=ts&"
import style0 from "./NewHorse.vue?vue&type=style&index=0&id=00590456&lang=scss&scoped=true&"
import style1 from "./NewHorse.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00590456",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VBtn,VCardActions,VCardText,VCardTitle,VCol,VIcon,VNavigationDrawer,VProgressLinear,VRangeSlider,VRow,VSkeletonLoader})
