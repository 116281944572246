import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';

Sentry.init({
    Vue,
    dsn: 'https://9731f81057914d91a3b245b9d1d381a5@o483614.ingest.sentry.io/5835384',
    integrations: [new Integrations.BrowserTracing()],
    attachProps: true,
    tracesSampleRate: 1.0,
    release: "admin-client@0.5.0",
    environment: 'production'
});

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.min.css'
import vueMoment from 'vue-moment'
import {EventHub} from './plugins/EventHub'
import io from 'socket.io-client';

// export const socket = io('https://g1racesoft.com/');
// export const socket = io('http://ec2-3-26-28-7.ap-southeast-2.compute.amazonaws.com/');
export const socket = io(window.location.origin);
// export const socket = io('http://127.0.0.1:3000');

import store from './store'
import VueSocketIOExt from 'vue-socket.io-extended';

Vue.config.productionTip = false

Vue.use(vueMoment)
Vue.use(EventHub)

Vue.use(VueSocketIOExt, socket, {store});

const vm = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
