























































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {copyToClipboard, StallionDetailsResource} from '@/store/types'
import {Socket} from 'vue-socket.io-extended'
import HorseFinder from '@/components/HorseFinder.vue'
import FarmFinder from '@/components/FarmFinder.vue'
import ImageUploader from '@/components/ImageUploader.vue'

const range = (start: number, end: number) => [...Array(end - start + 1).keys()].map(d => d + start)

@Component({
  components: {ImageUploader, FarmFinder, HorseFinder}
})
export default class StallionUpdate extends Vue {
  editMode = true
  stallionId = 0
  detailLoading = false
  noInformation = false

  requiredFiledRole = [
    (v: string | null) => !!v || '',
  ]


  @Watch('$route')
  onRouteChange() {
    this.editMode = this.$route.name === 'StallionMatch.stallions.update'
    if (this.editMode) {
      this.stallionId = Number(this.$route.params.id)
      this.detailLoading = true
      this.$socket.client.emit('stallion_details', {stallionID: this.stallionId})
    }
  }

  details: StallionDetailsResource = {
    StallionID: 0,
    FarmID: 0,
    FarmName: '',
    StallionHorseID: 0,
    StallionName: '',
    StallionYoB: null,
    StallionCoBID: null,
    StallionCoB: null,
    StallionSire: null,
    StallionDam: null,
    StallionURLID: null,
    StallionURL: null,
    StallionImage: null,
    StallionFee: null,
    StallionFeeCurrency: null,
    StallionFeeStatus: null,
    StallionYearToStud: null,
    StallionYearToRetired: null,
    StallionDateStart: null,
    StallionDateEnd: null,
    // eslint-disable-next-line @typescript-eslint/camelcase
    StallionPromotion20_20: null,
    StallionPromotionPerfectMatch: null,
    IsStallionSponsored: null,
    StallionCountryID: null,
    StallionCountryName: null,
    StallionStateID: null,
    StallionState: null,
    StallionAutoRenew: false,
    IsStallionActive: true,
    StallionUpdatedAt: '',
    StallionCreatedAt: ''
  }
  stallionURL = ''

  updateOrNew() {
    if (this.formState) {
      const request = {
        farmID: this.details.FarmID,
        horseID: this.details.StallionHorseID,
        urlID: null,
        image: null,
        fee: this.details.StallionFee,
        feeCurrency: this.details.StallionFeeCurrency,
        feeStatus: this.details.StallionFeeStatus,
        yearToStud: this.details.StallionYearToStud,
        yearToRetired: this.details.StallionYearToRetired,
        dateStart: null,
        dateEnd: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        promotion20_20: this.details.StallionPromotion20_20,
        promotionPerfectMatch: this.details.StallionPromotionPerfectMatch,
        isSponsored: this.details.IsStallionSponsored,
        countryID: this.details.StallionCountryID,
        stateID: this.details.StallionStateID,
        autoRenew: this.details.StallionAutoRenew,
        isActive: this.details.IsStallionActive,
        stallionID: this.details.StallionID
      }
      if (this.editMode) {
        this.detailLoading = true
        this.$socket.client.emit('stallion_update', request)
        this.stallionURL = `G1StallionMatch - ${this.details.FarmName.toUpperCase().replace(/ /g, '')}-${this.details.StallionName.toUpperCase().replace(/ /g, '')}.htm`
        this.$socket.client.emit('stallion_url_update', {stallionId: this.details.StallionID, url: this.stallionURL})
      } else {
        this.detailLoading = true
        this.$socket.client.emit('stallion_new', request)
      }

    }
  }

  successSnackbar = false

  @Socket('stallion_update')
  @Socket('stallion_new')
  onStallionUpdated(item: StallionDetailsResource) {
    this.stallionURL = `G1StallionMatch - ${this.details.FarmName.toUpperCase().replace(/ /g, '')}-${this.details.StallionName.toUpperCase().replace(/ /g, '')}.htm`
    this.$socket.client.emit('stallion_url_update', {stallionId: item.StallionID, url: this.stallionURL})
    this.successSnackbar = true
    this.onDetailsChange(item)
    setTimeout(() => {
      this.$router.back()
    }, 100)
  }

  @Socket('stallion_details')
  onDetailsChange(item: StallionDetailsResource) {
    this.detailLoading = false
    this.details = item
    this.stallionURL = this.details.StallionURL || ''
    if (this.details.StallionImage) {
      this.$socket.client.emit('stallion_image', {stallionId: this.details.StallionID})
    }
    this.loadStates()
  }

  created() {
    this.onRouteChange()
  }

  get countries() {
    return this.$store.getters.countries
  }

  get states() {
    return this.$store.getters.states
  }

  get loadings() {
    return this.$store.getters.loading
  }

  get feeStatus() {
    return [
      'FOA',
      'TBA',
      'Firm',
      'PROV',
      'Est.',
      'PRIVATE',
    ]
  }

  get years() {
    return range(1990, 2025).reverse()
  }

  loadStates() {
    if (this.details.StallionCountryID) {
      this.$store.dispatch('stateIndex', this.details.StallionCountryID)
    }
  }

  horseDialog = false

  selectHorse(item: any) {
    this.details.StallionName = item.HorseName
    this.details.StallionHorseID = item.HorseID
    this.horseDialog = false
  }

  removeFarm() {
    this.detailLoading = true
    this.$socket.client.emit('stallion_remove_farm', this.details.StallionID)
  }

  @Socket('stallion_remove_farm')
  onFarmUnAssigned() {
    this.details.FarmID = 0
    this.details.FarmName = ''
    this.detailLoading = false
  }

  farmDialog = false

  farmSelected(info: { FarmName: string; FarmID: number }) {
    this.details.FarmID = info.FarmID
    this.details.FarmName = info.FarmName
    this.stallionURL = `G1StallionMatch - ${this.details.FarmName.toUpperCase().replace(/ /g, '')}-${this.details.StallionName.toUpperCase().replace(/ /g, '')}.htm`
  }


  imgBuffer: ArrayBuffer | null = null
  stallionImage = ''
  imgUploadLoading = false

  uploadImage(buffer: ArrayBuffer) {
    this.imgBuffer = buffer
    if (this.editMode) {
      this.doUploadImage()
    }
  }

  doUploadImage() {
    this.imgUploadLoading = true
    this.$socket.client.emit('stallion_image_update', {stallionId: this.details.StallionID, image: this.imgBuffer})
  }

  @Socket('stallion_image')
  onImageReceive(buf: ArrayBuffer | null) {
    this.imgUploadLoading = false
    if (buf) {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        this.stallionImage = e.target?.result as string
      }
      const blob = new Blob([buf], {type: 'image/png'})
      reader.readAsDataURL(blob)
    }
  }

  copyDoneSnackbar = false

  copyURLToClipboard() {
    copyToClipboard(encodeURI('https://g1goldmine.com/g1g/ui/stallionmatch-result?for=' + this.stallionURL))
    this.copyDoneSnackbar = true
  }

  formState = false

  onSponsoredChange() {
    this.stallionURL = `G1StallionMatch - ${this.details.FarmName.toUpperCase().replace(/ /g, '')}-${this.details.StallionName.toUpperCase().replace(/ /g, '')}.htm`
    this.$socket.client.emit('stallion_url_update', {stallionId: this.details.StallionID, url: this.stallionURL})
  }
}
