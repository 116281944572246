











import {Component, Vue, Watch} from "vue-property-decorator"

@Component
export default class AccessDenied extends Vue {

  get user() {
    return this.$store.getters.user
  }

  @Watch('user')
  onUserChange() {
    if (this.user.accessLevel > 3) {
      this.$router.back()
    }
  }
}
