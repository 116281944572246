
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import moment from "moment";
import {DynamicSummaryClientManifest, DynamicViewCalenderConfig} from "@/store/types";

interface EventType {
  id: number;
  name: string;
  start: Date;
  end: Date;
  timed: boolean;
  color: string;
}

@Component
export default class CalendarView extends Vue {
  @Prop({required: true, type: Object})
  public manifest!: DynamicSummaryClientManifest;

  @Prop({required: true, type: Array})
  public items!: Array<Record<string, string>>

  @Prop({required: true, type: Number})
  calenderId!: number

  @Prop({required: false, type: Boolean, default: true})
  public loading!: number


  currentMoment = moment()
  currentViewDate = ''
  currentMonth = ''
  currentYear = 0

  monthLists = Array.apply(0, Array(12)).map(function (_, i) {
    return moment().month(i).format('MMM')
  })

  events: Array<EventType> = []
  dayDialog = false
  eventMode = false

  bootstrap = true

  calenderManifest!: DynamicViewCalenderConfig

  requestData() {
    const tmp = this.currentMoment.clone()
    tmp.set('date', 1)
    const start = tmp.toISOString()
    tmp.add({month: 1})
    const end = tmp.toISOString()
    this.$emit('update', [start, end])
  }

  created() {
    const tmp = this.manifest.calenders.find(r => r.id === this.calenderId)
    if (!tmp) {
      throw new Error('manifest not found!')
    }
    this.calenderManifest = tmp
    this.setDates()
  }

  setDates() {
    this.bootstrap = true
    this.currentViewDate = this.currentMoment.format('YYYY-MM-DD')
    this.currentMonth = this.currentMoment.format('MMM')
    this.currentYear = Number(this.currentMoment.format('YYYY'))
    setTimeout(() => {
      this.bootstrap = false
    }, 10)
    this.requestData()
  }

  @Watch('currentYear')
  onYearChange() {
    if (!this.bootstrap) {
      this.currentMoment.set({
        year: this.currentYear
      })
      this.setDates()
    }
  }

  @Watch('currentMonth')
  onMonthChange() {
    if (!this.bootstrap) {
      this.currentMoment.set({
        month: this.monthLists.indexOf(this.currentMonth)
      })
      this.setDates()
    }
  }

  selectEvent({event}: { event: EventType }) {
    this.eventMode = true
    this.$emit('click', this.items[event.id])
    setTimeout(() => {
      this.eventMode = false
    }, 10)
  }

  showDay({date}: any) {
    if (!this.eventMode) {
      this.currentViewDate = date
      this.dayDialog = true
    }
  }

  @Watch('items')
  onItemsChanged() {
    this.events = this.items.map((i, index) => ({
      color: this.getColor(i),
      id: index,
      name: i[this.calenderManifest.value],
      timed: this.getTimed(i),
      start: moment(i[this.calenderManifest.startAtValue]).toDate(),
      end: this.getEndAt(i)
    }))
  }

  getColor(item: Record<string, string>) {
    return (this.calenderManifest.colorValue ? item[this.calenderManifest.colorValue] : 'light-green darken-3') || 'light-green darken-3'
  }

  getTimed(item: Record<string, string>) {
    if (this.calenderManifest.endAtValue) {
      if (Object.prototype.hasOwnProperty.call(item, this.calenderManifest.endAtValue)) {
        const tmp = item[this.calenderManifest.endAtValue]
        if (tmp) {
          return false
        }
      }
    }
    return true
  }

  getEndAt(item: Record<string, string>) {
    if (this.calenderManifest.endAtValue) {
      if (Object.prototype.hasOwnProperty.call(item, this.calenderManifest.endAtValue)) {
        const tmp = item[this.calenderManifest.endAtValue]
        if (tmp) {
          return moment(tmp).toDate()
        }
      }
    }
    return moment(item[this.calenderManifest.startAtValue]).toDate()
  }
}
