




























import {Component, Vue} from "vue-property-decorator"
import NewFarm from "@/components/NewFarm.vue";
import FarmDetail from "@/components/FarmDetail.vue";
import DynamicSummaryPage from "@/components/DynamicSummary/DynamicSummaryPage.vue";
import {FarmDetailsResource, FarmSummaryResource} from "@/store/types";

@Component({
  components: {DynamicSummaryPage, FarmDetail, NewFarm}
})
export default class StallionMatchFarms extends Vue {
  farmDetailsDialog = false
  selectedFarm: FarmSummaryResource = {
    FarmID: 0,
    FarmName: '',
    FarmStateName: '',
    FarmCountryName: '',
    SponsoredStallion: 0,
    StallionCount: 0,
    FarmMemberCount: 0
  }


  openNewFarm() {
    this.$router.push({name: 'StallionMatch.farms.new'})
  }

  openDetail(item: FarmSummaryResource) {
    console.log(item)
    this.selectedFarm = item
    this.$nextTick(() => {
      this.farmDetailsDialog = true
    })
  }

  farmDeleteLoading = false
  farmDeleteDialog = false

  deleteFarm(item: FarmDetailsResource) {
    this.farmDetailsDialog = false
    this.selectedFarm = item
    this.farmDeleteDialog = true
    this.farmDeleteLoading = false
  }

  goToUpdatePage(item: FarmDetailsResource) {
    this.farmDetailsDialog = false
    this.$router.push({name: 'StallionMatch.farms.update', params: {id: item.FarmID.toString()}})
  }


  goToMemberPage(item: FarmDetailsResource) {
    this.farmDetailsDialog = false
    this.$router.push({name: 'StallionMatch.farms.update.member', params: {id: item.FarmID.toString()}})
  }

  goToStallionPage(item: FarmDetailsResource) {
    this.farmDetailsDialog = false
    this.selectedFarm = {
      FarmID: 0,
      FarmName: '',
      FarmStateName: '',
      FarmCountryName: '',
      SponsoredStallion: 0,
      StallionCount: 0,
      FarmMemberCount: 0
    }
    this.$router.push({
      name: 'StallionMatch.stallions',
      query: {farmId: item.FarmID.toString(), farmName: item.FarmName}
    })
  }

  deleteFarmForce() {
    this.farmDeleteLoading = true
    this.$socket.client.emit('farms_delete', {farmId: this.selectedFarm.FarmID})
  }
}
