










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Dashboard extends Vue {
  width = 1140
  height = 541

  mounted() {
    const elSize = this.$el.getBoundingClientRect()
    this.width = elSize.width
    this.height = window.innerHeight - 64
  }
}
