







































import { Vue, Component } from 'vue-property-decorator'
import DynamicSummaryPage from '@/components/DynamicSummary/DynamicSummaryPage.vue'
@Component({
  components: { DynamicSummaryPage }
})
export default class Member extends Vue {
  mounted() {
    if (this.$store.getters.user.accessLevel < 3) {
      this.$router.push({name: 'errors.accessDenied'})
    }
    if (this.$route.name === 'member') {
      this.$router.push({name: 'member.MemberSubscription'})
    }
  }
}
