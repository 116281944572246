import Vue from 'vue';
import Vuex from 'vuex';
import {
  Country,
  FarmSummaryResource, HorseColor,
  HorseSummaryResource,
  SalesCompanyResource,
  SalesInfoResource,
  SalesSummaryResource,
  SalesTypeResource,
  StallionSummaryResource,
  State
} from './types';
import { socket } from '@/main';
import * as Sentry from '@sentry/vue';

Vue.use(Vuex);

interface VuexState {
  loading: {
    countries: boolean;
    colors: boolean;
    states: boolean;
    farmSummary: boolean;
    stallionSummary: boolean;
    horseSummary: boolean;
    salesCatalogInfo: {
      types: boolean;
      infos: boolean;
      companies: boolean;
      sales: boolean;
    };
  };
  countries: Array<Country>;
  colors: Array<HorseColor>;
  states: Array<State>;
  farmSummary: {
    finalLength: number;
    items: Array<FarmSummaryResource>;
    page: number;
    perPage: number;
    query: any;
  };
  stallionSummary: {
    finalLength: number;
    items: Array<StallionSummaryResource>;
    page: number;
    perPage: number;
    query: any;
    sorts: Array<never>;
  };
  horseSummary: {
    finalLength: number;
    items: Array<HorseSummaryResource>;
    page: number;
    perPage: number;
    query: any;
    sort: any;
  };
  user: {
    fullName: string;
    accessLevel: number;
    memberID: number;
  };
  salesCatalogInfo: {
    types: Array<SalesTypeResource>;
    infos: Array<SalesInfoResource>;
    companies: Array<SalesCompanyResource>;
    sales: Array<SalesSummaryResource>;
  };
  roles: Array<{ subject: string; action: string }>;
}

export default new Vuex.Store<VuexState>({
  state: {
    loading: {
      countries: false,
      colors: false,
      states: false,
      farmSummary: false,
      stallionSummary: false,
      horseSummary: false,
      salesCatalogInfo: {
        companies: false,
        infos: false,
        sales: false,
        types: false,
      }
    },
    countries: [],
    colors: [],
    states: [],
    farmSummary: {
      finalLength: 0,
      items: [],
      page: 0,
      perPage: 18,
      query: {}
    },
    stallionSummary: {
      finalLength: 0,
      items: [],
      page: 0,
      perPage: 18,
      query: {},
      sorts: []
    },
    horseSummary: {
      finalLength: 18,
      items: [],
      page: 0,
      perPage: 18,
      query: {},
      sort: undefined
    },
    user: {
      fullName: '',
      accessLevel: 0,
      memberID: 0
    },
    salesCatalogInfo: {
      companies: [],
      infos: [],
      sales: [],
      types: []
    },
    roles: []
  },
  mutations: {
    SOCKET_COUNTRIES_INDEX (state, countries: Array<Country>) {
      state.countries = countries;
      state.loading.countries = false;
    },
    SOCKET_COLORS_INDEX (state, colors: Array<HorseColor>) {
      state.colors = colors.sort((a,b) => a.colourName > b.colourName ? 1:-1);
      state.loading.colors = false;
    },
    SOCKET_STATE_INDEX (state, states: Array<State>) {
      state.states.splice(0, state.states.length);
      states.forEach((d: State) => state.states.push(d));
      state.loading.states = false;
    },
    SOCKET_FARMS_INDEX (state, result) {
      state.loading.farmSummary = false;
      state.farmSummary.items = result.result.map((d: FarmSummaryResource) => {
        d.StallionCount = d.StallionCount || 0;
        d.SponsoredStallion = d.SponsoredStallion || 0;
        d.FarmMemberCount = d.FarmMemberCount || 0;
        d.FarmName = d.FarmName || '-';
        return d;
      });
      state.farmSummary.page = result.page.page;
      state.farmSummary.perPage = result.page.pageSize;
    },
    SOCKET_FARMS_INDEX_COUNT (state, res) {
      state.farmSummary.finalLength = res;
    },
    SOCKET_FARMS_DELETE (state, res) {
      const index = state.farmSummary.items.findIndex(d => d.FarmID === res);
      if (index !== - 1) {
        state.farmSummary.items.splice(index, 1);
        state.farmSummary.finalLength --;
      }
    },
    SOCKET_STALLION_INDEX (state, result) {
      state.loading.stallionSummary = false;
      state.stallionSummary.items = result.result.map((d: StallionSummaryResource) => {
        d.FarmName = d.FarmName || '-';
        return d;
      });
      state.stallionSummary.page = result.page.page;
      state.stallionSummary.perPage = result.page.pageSize;
    },
    SOCKET_STALLION_INDEX_COUNT (state, res) {
      state.stallionSummary.finalLength = res;
    },
    SOCKET_STALLION_DELETE (state, res) {
      const index = state.stallionSummary.items.findIndex(d => d.StallionID === res);
      if (index !== - 1) {
        state.stallionSummary.items.splice(index, 1);
        state.stallionSummary.finalLength --;
      }
    },
    SOCKET_HORSES_INDEX (state, result) {
      state.loading.horseSummary = false;
      state.horseSummary.items = result.result.map((d: HorseSummaryResource) => {
        d.HorseRunCount = d.HorseRunCount || 0;
        d.HorseWinCount = d.HorseWinCount || 0;
        d.HorseFamilyTrackingCount = d.HorseFamilyTrackingCount || 0;
        d.HorseDependencyCount = d.HorseDependencyCount || 0;
        d.HorseCreatedAt = new Date(d.HorseCreatedAt);
        return d;
      });
      state.horseSummary.page = result.page.page;
      state.horseSummary.perPage = result.page.pageSize;
    },
    SOCKET_HORSES_INDEX_COUNT (state, res) {
      state.horseSummary.finalLength = res;
    },
    SOCKET_SALES_TYPES_LIST (state, res) {
      state.salesCatalogInfo.types = res;
      state.loading.salesCatalogInfo.types = false;
    },
    SOCKET_SALES_INFOS_LIST (state, res) {
      state.salesCatalogInfo.infos = res;
      state.loading.salesCatalogInfo.infos = false;

    },
    SOCKET_SALES_COMPANIES_LIST (state, res) {
      state.salesCatalogInfo.companies = res;
      state.loading.salesCatalogInfo.companies = false;

    },
    SOCKET_SALES_SUMMARY (state, res) {
      state.salesCatalogInfo.sales = res;
      state.loading.salesCatalogInfo.sales = false;
    },
    SOCKET_AUTHORITIES_LIST(state, res) {
      state.roles = res
    },
    SET_AUTHENTICATED_USER (state, data: any) {
      state.user = data;
      Sentry.setUser({ username: data.emailAddress, id: data.memberID, email: data.emailAddress });
    }
  },
  actions: {
    countryIndex ({ state }) {
      state.loading.countries = true;
      socket.emit('countries_index');
    },
    colorsIndex ({ state }) {
      state.loading.colors = true;
      socket.emit('colors_index');
    },
    stateIndex ({ state }, countryId) {
      state.loading.states = true;
      socket.emit('state_index', {
        where: {
          countryId
        }
      });
    },
    initFarmSummaryPagination ({ state }, { query, pageSize }) {
      state.loading.farmSummary = true;
      this.state.farmSummary = {
        finalLength: 0,
        items: [],
        page: 1,
        perPage: 10,
        query
      };
      socket.emit('farms_index_count', { where: query });
      socket.emit('farms_index', { where: query, page: { page: 0, pageSize } });
    },
    loadFarmSummary ({ state }, { page, pageSize }) {
      state.loading.farmSummary = true;
      socket.emit('farms_index', { where: state.farmSummary.query, page: { page, pageSize } });
    },
    initStallionSummaryPagination ({ state }, { query, pageSize, sorts }) {
      state.loading.stallionSummary = true;
      this.state.stallionSummary = {
        finalLength: 0,
        items: [],
        page: 1,
        perPage: 10,
        query,
        sorts
      };
      socket.emit('stallion_index_count', { where: query });
      socket.emit('stallion_index', { where: query, sorts, page: { page: 0, pageSize } });
    },
    loadStallionSummary ({ state }, { page, pageSize }) {
      state.loading.stallionSummary = true;
      socket.emit('stallion_index', {
        where: state.stallionSummary.query,
        sorts: state.stallionSummary.sorts,
        page: { page, pageSize }
      });
    },
    initHorsesSummaryPagination ({ state }, { query, pageSize, sort }) {
      state.loading.horseSummary = true;
      this.state.horseSummary = {
        finalLength: 0,
        items: [],
        page: 1,
        perPage: 10,
        query,
        sort
      };
      socket.emit('horses_index_count', { where: query });
      socket.emit('horses_index', {
        where: query,
        sort: state.horseSummary.sort, page: { page: 0, pageSize, sort }
      });
    },
    loadHorsesSummary ({ state }, { page, pageSize }) {
      state.loading.horseSummary = true;
      socket.emit('horses_index', {
        where: state.horseSummary.query,
        sort: state.horseSummary.sort,
        page: { page, pageSize }
      });
    },
    loadSalesTypes ({ state }) {
      state.loading.salesCatalogInfo.types = true;
      socket.emit('sales_types_list');
    },
    loadSalesInfos ({ state }) {
      state.loading.salesCatalogInfo.infos = true;
      socket.emit('sales_infos_list');
    },
    loadSalesCompanies ({ state }) {
      state.loading.salesCatalogInfo.companies = true;
      socket.emit('sales_companies_list');
    },
    loadSalesSummary ({ state }) {
      state.loading.salesCatalogInfo.sales = true;
      socket.emit('sales_summary');
    },
  },
  getters: {
    loading: state => state.loading,
    countries: state => state.countries,
    colors: state => state.colors,
    states: state => state.states,
    farmSummary: state => state.farmSummary,
    stallionSummary: state => state.stallionSummary,
    horseSummary: state => state.horseSummary,
    user: state => state.user,
    roles: state => state.roles,
    salesCatalogInfo: state => state.salesCatalogInfo,
  }
});
