
























import {Component, Vue} from 'vue-property-decorator'
import {Socket} from "vue-socket.io-extended";
import MemberFinder from "@/components/MemberFinder.vue";
import AdminDetails from "@/views/Admin/admin-details.vue";

@Component({
  components: {AdminDetails, MemberFinder}
})
export default class AdminUsersList extends Vue {
  users: Array<{ email: string; firstName: string; lastName: string }> = []
  usersLoading = true
  userFinder = false
  userHeaders = [
    {text: 'first name', value: 'firstName'},
    {text: 'last name', value: 'lastName'},
    {text: 'email', value: 'UserName'},
  ]

  detailDialog = false
  selectedUserId = 0
  selectedUser = null

  select(user: any) {
    this.selectedUserId = user.memberID;
    this.selectedUser = user
    this.detailDialog = true
  }

  selectMember({MemebrID}: any) {
    this.$socket.client.emit('authorities_add', {
      userId: MemebrID, subject: 'authorities', action: 'login'
    })
    this.usersLoading = true
  }

  mounted() {
    this.$socket.client.emit('admin_list')
  }

  @Socket('admin_list')
  onAdminsLoaded(users: Array<any>) {
    this.users = users
    this.usersLoading = false
  }

  @Socket('authorities_add')
  onAdded() {
    this.$socket.client.emit('admin_list')
  }
}
