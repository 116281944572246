































import {Component, Vue, Watch} from "vue-property-decorator"
import Pedigree from "@/components/Pedigree.vue";
import HorseDetailUpdate from "@/components/HorseDetailUpdate.vue";
import {HorseResource} from "@/store/types";

@Component({
  components: {HorseDetailUpdate, Pedigree}
})
export default class HorseUpdate extends Vue {
  mode = 0
  horseId = 0

  @Watch('$route')
  onRouteChange() {
    this.horseId = Number(this.$route.params.id)
  }

  created() {
    this.onRouteChange()
  }

  deleteSuccess = false

  onHorseDeleted() {
    this.deleteSuccess = true
    setTimeout(() => {
      this.$router.back()
    }, 2)
  }
}
