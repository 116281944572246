
































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Country,
  HorseDetailResource,
  horseDetailToHorseResource,
  HorseFinderMiniResource,
  ObjectOf,
  PedigreeResourceTemporaryAble,
  PedigreeRowResource,
  PedigreeRowResourceNew
} from '@/store/types';
import HorseFinder from '@/components/HorseFinder.vue';
import HorseDetailUpdate from '@/components/HorseDetailUpdate.vue';
import { Socket } from 'vue-socket.io-extended';
import HorseDetailUpdateMini from '@/components/HorseDetailUpdateMini.vue';


const nameConvertorHelper: { [key: string]: string } = {};
@Component({
  components: { HorseDetailUpdateMini, HorseDetailUpdate, HorseFinder }
})
export default class NewHorse extends Vue {
  showAbleDepth = [0, 5];
  pedigree: ObjectOf<PedigreeResourceTemporaryAble> = {};
  temporaryHorses: ObjectOf<HorseDetailResource> = {};
  selectedRowPosition = '';
  loading = false;
  createHorseDrawer = false;
  horseFinderDialog = false;

  @Prop({ type: Boolean, default: false })
  dialogMode!: boolean;

  get showFromDepth () {
    return this.showAbleDepth[0];
  }

  get showUntilDepth () {
    return this.showAbleDepth[1] + 1;
  }

  twoToPow (i: number) {
    return Math.pow(2, i - 1);
  }

  getGenerationByRowAndCol (row: number, size: number): string {
    size --;
    if (size <= 0) return 'H';
    if (nameConvertorHelper[`${row}/${size}`]) {
      return nameConvertorHelper[`${row}/${size}`];
    }
    const res = (row >>> 0).toString(2);
    let prefix = '';
    for (let i = 0; i < size - res.length; i ++) {
      prefix += '0';
    }
    const pos = (prefix + res).replace(/0/g, 'S').replace(/1/g, 'D');
    nameConvertorHelper[`${row}/${size}`] = pos;
    return pos;
  }

  getTooltip (i: number, j: number) {
    const pos = this.getGenerationByRowAndCol(i, j);
    const info = this.pedigree[pos];
    if (info) {
      return `${pos}(${info.isTmp ? 'tmp' : 'in DB'}): ${info.HorseName} \r\n(${info.HorseCoBCode}, ${info.HorseYob})`;
    } else return pos;
  }

  get depthNames () {
    const names = [...Array(8).keys()].map((d) => `Gen ${Number(d) + 1}`);
    names.unshift('Horse');
    return names;
  }

  get countries () {
    return this.$store.getters.countries;
  }

  choseHorse (selectedPosition: string) {
    this.selectedRowPosition = selectedPosition;
    this.horseFinderDialog = true;
  }

  pedigreeLoadResolver: any = false;

  updatePedigree (horse: HorseFinderMiniResource & { HorseSex: 'F' | 'M' }) {
    if (horse.HorseSex === 'F' && this.selectedRowPosition.endsWith('S') || horse.HorseSex === 'M' && this.selectedRowPosition.endsWith('D')) {
      alert('Wrong Horse Sex! Please select correct horse.');
      setTimeout(() => {
        this.horseFinderDialog = true;
      });
      return false;
    }
    return new Promise(r => {
      this.pedigreeLoadResolver = r;
      if (this.selectedRowPosition === 'H') {
        this.$router.push({ name: 'Data.horse.update', params: { id: horse.HorseID.toString() } });
      }
      this.loading = true;
      this.pedigree[this.selectedRowPosition] = {
        HorseName: horse.HorseName,
        HorsePosition: this.selectedRowPosition,
        HorseID: horse.HorseID,
        HorseYob: horse.HorseYearOfBirth,
        HorseCoBCode: this.countries.find((d: Country) => d.CountryID === horse.HorseNationality)?.CountryCode,
        HorseSex: horse.HorseSex,
        HorseVerifiedTime: null,
        HorseVerifiedBy: null,
        //-------------------------------------------
        isTmp: false,
        isDefinedByUser: true,
      };
      this.$socket.client.emit('horses_pedigree', { horseId: horse.HorseID });
    });
  }

  @Socket('horses_pedigree')
  onPedigreeLoaded (value: Array<PedigreeRowResourceNew>) {
    value.forEach(i => {
      if (i.HorsePosition !== '') {
        const pos = this.selectedRowPosition + i.HorsePosition;
        if (pos.length <= 8) {
          this.pedigree[pos] = { ...i, isTmp: false, isDefinedByUser: false };
          if (this.temporaryHorses[pos]) {
            delete this.temporaryHorses[pos];
          }
        }
      }
    });
    this.loading = false;
    if (this.pedigreeLoadResolver) {
      this.pedigreeLoadResolver();
    }
  }

  horseReceived (horse: HorseDetailResource) {
    if (horse.HorseSex === 'F' && this.selectedRowPosition.endsWith('S') || horse.HorseSex === 'M' && this.selectedRowPosition.endsWith('D')) {
      alert('Wrong Horse Sex!');
      setTimeout(() => {
        this.createHorseDrawer = true;
      });
      return false;
    }
    this.temporaryHorses[this.selectedRowPosition] = { ...horse };
    this.pedigree[this.selectedRowPosition] = {
      HorseName: horse.HorseName,
      HorsePosition: this.selectedRowPosition,
      HorseID: 0,
      HorseYob: horse.HorseYoB,
      HorseSex: horse.HorseSex,
      HorseVerifiedBy: horse.HorseVerifiedBy,
      HorseVerifiedTime: horse.HorseVerifiedAt,
      HorseCoBCode: this.countries.find((d: Country) => d.CountryID === horse.HorseCoBID)?.CountryCode,
      isTmp: true,
      isDefinedByUser: true,
      HorseIsProtected: horse.HorseIsProtected
    };
    this.createHorseDrawer = false;
    if (this.dialogMode) {
      this.save();
    }
  }

  createHorse (selectedPosition?: string) {
    if (selectedPosition) {
      this.selectedRowPosition = selectedPosition;
    }
    this.editMode = false;
    this.horseFinderDialog = false;
    this.$nextTick(() => {
      this.createHorseDrawer = true;
    });
    const t: HorseDetailUpdate = this.$refs.details as HorseDetailUpdate;
    if (this.temporaryHorses[this.selectedRowPosition]) {
      t.reset({ ...this.temporaryHorses[this.selectedRowPosition] });
    } else {
      t.reset();
    }
  }

  error = false;
  errorPosition = '';
  saveLoading = false;
  saveAction = '';

  savePromiseResolve: (() => void) | false = false;
  savePromiseReject: (() => void) | false = false;
  lastRequestResultID = 0;

  setPromise () {
    return new Promise((resolve, reject) => {
      this.savePromiseResolve = resolve;
      this.savePromiseReject = reject;
    });
  }


  @Socket('horses_pedigree_update')
  @Socket('horses_new')
  async onHorseCreated (data: { result: HorseDetailResource; userData: string } | Array<PedigreeRowResource>) {
    if (!Array.isArray(data)) {
      this.lastRequestResultID = data.result.HorseID;
      this.pedigree[this.selectedRowPosition].HorseID = data.result.HorseID;
      this.pedigree[this.selectedRowPosition].isTmp = false;
    }
    if (this.savePromiseResolve !== false) {
      this.savePromiseResolve();
      this.savePromiseResolve = false;
    }
  }

  async save () {
    // validate
    let positions: Array<string> = [];
    for (let gen = 1; gen <= 9; gen ++) {
      for (let row = 0; row < Math.pow(2, gen - 1); row ++) {
        const pos = this.getGenerationByRowAndCol(row, gen);
        if (pos.length <= 5) {
          if (!this.pedigree[pos]) {
            this.error = true;
            this.errorPosition = pos;
            return;
          }
        }
        if (pos !== 'H') {
          positions.push(pos);
        }
      }
    }
    this.saveLoading = true;
    positions = positions.sort((a, b) => b.length - a.length || (a < b ? 1 : - 1));
    positions.push('H');
    // creation
    for (const pos of positions) {
      this.selectedRowPosition = pos;
      const horse = this.pedigree[pos];
      if (horse) {
        let damPos = pos + 'D';
        let sirePos = pos + 'S';
        if (pos === 'H') {
          damPos = 'D';
          sirePos = 'S';
        }
        const sire = this.pedigree[sirePos];
        const dam = this.pedigree[damPos];
        if (horse.isTmp) {
          if (horse.HorseName) {
            const horseResource = horseDetailToHorseResource(this.temporaryHorses[pos]);

            horseResource.sireId = sire?.HorseID;
            horseResource.damId = dam?.HorseID;
            this.$socket.client.emit('horses_new', { details: horseResource, userData: pos });
            this.saveAction = `Saving ${horseResource.horseName} AS (${pos})`;
            await this.setPromise();
          } else {
            console.error(pos, horse.HorseName, horse);
            return;
          }
        } else {
          if (sire?.isTmp || dam?.isTmp || sire?.isDefinedByUser || dam?.isDefinedByUser) {
            this.$socket.client.emit('horses_pedigree_update', {
              horseID: horse.HorseID,
              damID: dam?.HorseID,
              sireID: sire?.HorseID
            });
            this.saveAction = `Updating ${horse.HorseName} (${pos}) Pedigree`;
            await this.setPromise();
          }
        }
      }
    }
    this.saveLoading = false;
    if (!this.dialogMode) {
      await this.$router.push({ name: 'Data.horse.update', params: { id: this.pedigree['H'].HorseID.toString() } });
    } else {
      this.$emit('done', this.pedigree['H']);
    }
  }

  // edit part
  editMode = false;
  selectedEditItemID = 0;

  loadFullData () {
    const routeData = this.$router.resolve({
      name: 'Data.horse.update',
      params: { id: this.selectedEditItemID.toString() }
    });
    window.open(routeData.href, '_blank');
    this.createHorseDrawer = false;
    this.editMode = false;
  }

  editPrimary (pos: string) {
    if (this.pedigree[pos]) {
      if (!this.pedigree[pos].isTmp) {
        this.selectedEditItemID = this.pedigree[pos].HorseID;
        this.editMode = true;
        this.$nextTick(() => {
          this.createHorseDrawer = true;
        });
      }
    }
  }

  getColor (horse: PedigreeRowResourceNew | null, pos: string) {
    if (horse) {
      if (
          ((pos.endsWith('S') && horse.HorseSex === 'F') || (pos.endsWith('D') && horse.HorseSex === 'M')) ||
          (!(horse.HorseCoBCode && horse.HorseCoBCode.length > 0)) || (!(horse.HorseYob && horse.HorseYob.toString().length > 0)) ||
          (!(horse.HorseYob && !isNaN(Number(horse.HorseYob)) && Number(horse.HorseYob) > 1000)) ||
          (horse.HorseCoBCode === 'NRF' || horse.HorseCoBCode === 'UNK')
      ) {
        return 'has-error-text';
      }
      if (pos !== '' && pos !== 'h') {
        if (pos.endsWith('S')) {
          if (Number(this.pedigree[pos.slice(0, - 1)]?.HorseYob) - Number(horse.HorseYob) > 30) {
            return 'has-error-2-text';
          }
        }
        if (pos.endsWith('D')) {
          if (Number(this.pedigree[pos.slice(0, - 1)]?.HorseYob) - Number(horse.HorseYob) > 26) {
            return 'has-error-2-text';
          }
        }
      }
      if (!horse.HorseVerifiedBy) {
        return 'has-warn-text';
      }
    }
    return '';
  }
}
