






import { Component, Vue } from 'vue-property-decorator'
import DynamicSummaryPage from '@/components/DynamicSummary/DynamicSummaryPage.vue'

@Component({
  components: { DynamicSummaryPage }
})
export default class RenamedHorse extends Vue {

}
