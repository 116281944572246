











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class NumberRangePicker extends Vue {
  min = null
  max = null

  @Prop({ default: null })
  value!: string | null

  @Prop({ default: null })
  placeholder!: string | null

  get minPlaceholder() {
    if (this.placeholder) {
      const data = this.placeholder.split('|')
      if (data[0]) {
        return data[0]
      }
    }
    return ''
  }

  get maxPlaceholder() {
    if (this.placeholder) {
      const data = this.placeholder.split('|')
      if (data[1]) {
        return data[1]
      }
    }
    return ''
  }

  @Prop({ default: null })
  label!: string | null

  get minLabel() {
    if (this.label) {
      const data = this.label.split('|')
      if (data[0]) {
        return data[0]
      }
    }
    return ''
  }

  get maxLabel() {
    if (this.label) {
      const data = this.label.split('|')
      if (data[1]) {
        return data[1]
      }
    }
    return ''
  }

  @Watch('value')
  onValueChange () {
    if (this.value === null) {
      this.min = null
      this.max = null
    }
  }

  @Watch('min')
  @Watch('max')
  submit () {
    this.$emit('input', `${this.min}|${this.max}`)
  }
}
