
































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {FarmSummaryResource, FarmDetailsResource, copyToClipboard} from "@/store/types";
import {Socket} from "vue-socket.io-extended";

@Component
export default class FarmDetail extends Vue {
  @Prop({default: false})
  value!: boolean

  @Prop({required: true})
  item!: FarmSummaryResource

  details: FarmDetailsResource = {
    FarmID: 6000,
    FarmName: 'test',
    FarmPhone: '+98 930 266 0045',
    FarmWebsite: 'google.com',
    FarmURL: 'google.com',
    FarmCountryName: 'Iran',
    FarmStateName: 'Tehran',
    FarmAddress: 'Some Where',
    FarmPostcode: '3516123146',
    createdAt: new Date()
  }

  dialog = false

  loading = false


  @Watch('value')
  onValueChange(value: boolean) {
    this.dialog = value
    if (!value) {
      setTimeout(this.reset.bind(this), 1000)
    } else {
      this.loading = true
      this.$socket.client.emit('farms_details', {farmId: this.item.FarmID})
    }
  }

  @Socket('farms_details')
  farmDetailLoaded(item: FarmDetailsResource) {
    this.details = item
    this.loading = false
  }

  @Watch('dialog')
  onDialogChange(dialog: boolean) {
    if (!dialog) {
      this.close()
    }
  }


  reset() {
    // todo
  }

  close() {
    this.$emit('input', false)
  }

  created() {
    this.onValueChange(this.value)
  }

  copyToClipboard(text: string) {
    copyToClipboard(text)
  }

  copyURL() {
    copyToClipboard(encodeURI('https://g1goldmine.com/g1g/ui/stallionmatch-result?for=' + this.details.FarmURL))
  }
}
