var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"height":"458px"},attrs:{"max-width":"60vw"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-nl"},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-barn ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.item.FarmName|| '-')+" ")]),_c('v-list-item-subtitle',[_vm._v(" Org name ")])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"mx-4",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('update', _vm.item)}}},[_c('v-icon',[_vm._v(" mdi-pencil-outline ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', _vm.item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{staticClass:"my-16",attrs:{"indeterminate":"","color":"primary","size":"96"}})],1):_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-list',[_c('v-subheader',[_vm._v(" Information ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Phone ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmPhone|| '-')+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.details.FarmPhone)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Website ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmWebsite|| '-')+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.details.FarmWebsite)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Farm URL ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmURL|| '-')+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyURL()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)]}}])}),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Created At ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.details.createdAt,"Do MMMM YYYY"))+" ")])],1)],1)],1),_c('div',{staticClass:"px-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('stallions',_vm.item)}}},[_c('div',[_vm._v("# OF STALLIONS")]),_c('div',{staticClass:"secondary--text text-h3"},[_vm._v(_vm._s(_vm.item.StallionCount || 0))]),_c('div',{staticClass:"secondary--text"},[_vm._v("VIEW STALLION LIST >")])])],1),_c('v-col',{staticClass:"py-0"},[_c('v-list',[_c('v-subheader',[_vm._v(" Location ")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Country ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmCountryName || '-')+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" State ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmStateName || '-')+" ")])],1)],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Address ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmAddress || '-')+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.details.FarmAddress)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Postcode ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.details.FarmPostcode|| '-')+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.details.FarmPostcode)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)]}}])})],1),_c('div',{staticClass:"px-4",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('members',_vm.item)}}},[_c('div',[_vm._v("# OF MEMBERS")]),_c('div',{staticClass:"secondary--text text-h3"},[_vm._v(_vm._s(_vm.item.FarmMemberCount || 0))]),_c('div',{staticClass:"secondary--text"},[_vm._v("VIEW MEMBER LIST >")])])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }