import { render, staticRenderFns } from "./StakeWinnerList.vue?vue&type=template&id=322aaa66&scoped=true&"
import script from "./StakeWinnerList.vue?vue&type=script&lang=ts&"
export * from "./StakeWinnerList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "322aaa66",
  null
  
)

export default component.exports