




























































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Socket} from "vue-socket.io-extended";
import DynamicSummaryPage from "@/components/DynamicSummary/DynamicSummaryPage.vue";
import {DynamicSummaryColumnsSearchType} from "@/store/types";

@Component({
  components: {DynamicSummaryPage}
})
export default class AdminDetails extends Vue {
  @Prop({required: true})
  userId!: number

  @Prop({required: true})
  user!: any

  selectedItem: string | null = null
  selectedItemDialog = false

  loading = true
  tab = 0
  roles: Array<{ subject: string; action: string }> = []
  expData = null
  menus: Array<any> = []

  sessions: Array<any> = []
  sessionsHeaders = [
    {text: 'login at', value: 'createdAt'},
    {text: 'last usage', value: 'lastUsage'},
    {text: 'ip', value: 'ip'},
    {text: 'usage', value: 'usage'},
    {text: 'os', value: 'userAgentOS'},
    {text: 'browser', value: 'userAgentBrowser'},
  ]
  sessionLoading = false

  haveAccess(subject: string, action: string) {
    return this.roles.findIndex(r => r.action === action && r.subject === subject) > -1
  }

  changeAccess(subject: string, action: string, value: boolean) {
    this.loading = true
    if (value) {
      this.$socket.client.emit('authorities_add', {userId: this.userId, subject, action})
    } else {
      this.$socket.client.emit('authorities_remove', {userId: this.userId, subject, action})
    }
  }

  created() {
    this.loading = true
    this.sessionLoading = true
    this.$socket.client.emit('authorities_list', this.userId)
    this.$socket.client.emit('device_find_all_user', this.userId)
    this.$socket.client.emit('dynamic_summary_menus')
  }

  get name() {
    return [this.user.firstName, this.user.middleName, this.user.lastName].filter(r => typeof r === 'string' && r.length > 0).join(' ')
  }

  get userSearchParameter() {
    return [{
      type: DynamicSummaryColumnsSearchType.text_exact,
      columnName: 'emailAddress',
      value: this.user.emailAddress,
    }]
  }

  @Socket('dynamic_summary_menus')
  onMenuLoaded({data}: any) {
    this.menus = data
  }

  @Socket('authorities_list')
  onListLoaded(res: Array<any>) {
    this.roles = res
    this.$nextTick(() => {
      this.loading = false
    })
  }

  @Socket('device_find_all_user')
  onSessionsLoaded(sessions: any) {
    this.sessions = sessions.sort((b: any, a: any) => new Date(a.lastUsage).getTime() - new Date(b.lastUsage).getTime())
    this.sessionLoading = false
  }

  @Socket('authorities_add')
  @Socket('authorities_remove')
  onStateChange() {
    this.$socket.client.emit('authorities_list', this.userId)
  }

  showItem(item: any) {
    this.selectedItem = JSON.stringify(JSON.parse(item.request), undefined, 4).replace(/ {4}/g, '\n')
    this.selectedItemDialog = true
  }

  beforeDestroy() {
    setTimeout(() => {
      this.$socket.client.emit('authorities_list', this.$store.getters.user.memberID)
    }, 10)
  }
}
