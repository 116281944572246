

































































import {Component, Vue, Watch} from "vue-property-decorator"
import {Socket} from "vue-socket.io-extended";
import {FarmMemberResource} from "@/store/types";
import MemberFinder from "@/components/MemberFinder.vue";

@Component({
  components: {MemberFinder}
})
export default class FarmMember extends Vue {
  headers = [
    {
      text: 'NAME',
      value: 'FarmMemberFullname'
    }, {
      text: 'EMAIL',
      value: 'FarmMemberEmail'
    }, {
      text: 'IS ADMIN',
      value: 'isAdmin'
    }, {
      text: 'ACTIONS',
      value: 'actions'
    },

  ]
  items: Array<FarmMemberResource> = []
  memberLoading = false
  farmId = 0
  userFinder = false


  selectMember(member: any) {
    this.userFinder = false
    this.selectedUser = member
  }

  @Watch('$route')
  onRouteChange() {
    this.farmId = Number(this.$route.params.id)
    this.memberLoading = true
    this.$socket.client.emit('farms_users_index', {farmId: this.farmId})
  }

  @Socket('farms_users_index')
  onUserChanges(users: Array<FarmMemberResource>) {
    this.actionLoading = false
    this.updateDialog = false
    this.removeDialog = false
    this.inviteUserDialog = false
    this.items = users
    this.memberLoading = false
  }

  selectedMember: FarmMemberResource = {
    FarmMemberFullname: '',
    MemberID: 0,
    farmID: 0,
    isAdmin: false,
    FarmMemberEmail: '',
    FarmMemberID: 0
  }
  actionLoading = false
  updateDialog = false
  removeDialog = false

  update(item: FarmMemberResource) {
    this.selectedMember = {...item}
    this.updateDialog = true
  }

  doUpdate() {
    this.actionLoading = true
    this.$socket.client.emit('farms_users_update', this.selectedMember)
  }

  remove(item: FarmMemberResource) {
    this.selectedMember = {...item}
    this.removeDialog = true
  }

  doRemove() {
    this.actionLoading = true
    this.$socket.client.emit('farms_users_delete', this.selectedMember)
  }

  inviteUserDialog = false
  // usersListLoading = false
  isAdmin = false
  users: Array<{ MemebrID: number; MemberEmailAddress: string; MemberFullname: string }> = []
  selectedUser: { MemebrID: number; MemberEmailAddress: string; MemberFullname: string } = {
    MemberFullname: '',
    MemebrID: 0,
    MemberEmailAddress: ''
  }

  // @Watch('userEmail')
  // updateUsersList() {
  //   this.usersListLoading = true
  //   this.$socket.client.emit('users_finder', {emailAddress: this.userEmail})
  // }

  // @Socket('users_finder')
  // onUserChange(items: Array<{ MemberID: number; MemberEmailAddress: string; MemberFullname: string }>) {
  //   setTimeout(() => {
  //     this.users = items
  //     this.usersListLoading = false
  //   }, 100)
  // }

  openInviteUserDialog() {
    this.users = []
    this.selectedUser = {
      MemberFullname: '',
      MemebrID: 0,
      MemberEmailAddress: ''
    }
    this.inviteUserDialog = true
  }

  addMember() {
    this.actionLoading = true
    this.$socket.client.emit('farms_users_add', {
      memberID: this.selectedUser.MemebrID,
      farmID: this.farmId,
      isAdmin: this.isAdmin
    })
  }

  mounted() {
    this.onRouteChange()
  }

}
