
















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Country, HorseFilterResource } from '@/store/types';
import HorseFinderField from '@/components/HorseFinderField.vue';

@Component({
  components: { HorseFinderField }
})
export default class HorseFilter extends Vue {
  datePickerFromMenu = false;
  datePickerToMenu = false;

  @Prop({
    required: true
  })
  filters!: HorseFilterResource;

  @Prop({
    type: Boolean
  })
  showReset!: boolean;

  @Watch('filters', { immediate: true })
  onFilterChanges () {
    this.internalFilers = this.filters;
  }

  internalFilers: HorseFilterResource = {
    horseName: '', // done
    exactMatch: true, // done
    country: null, // done
    state: null, // done
    from: null, // done
    to: null, // done
    horseFrom: null, // done
    yobMin: null, // done
    yobMax: null, // done
    sireId: null,
    damId: null,
    thoroughbred: 'All',
    eligible: 'All',
    sireStatus: 'All',
    damStatus: 'All',
    runner: 'All',
    stakesWinners: 'All',
    prizeMin: null,
    prizeMax: null,
    prizeCurrency: null,
    options: {
      unverified: false, // done
      familyTracking: false,
      stallionMatch: false,
      horseInformation: false,
      missingCOB: false, // done
      missingYOF: false // done
    }
  };

  @Watch('internalFilers', { deep: true })
  onFiltersChange () {
    this.$emit('update:filters', this.internalFilers);
  }

  get countries () {
    return this.$store.getters.countries;
  }

  get loadings () {
    return this.$store.getters.loading;
  }

  get thoroughbredItems () {
    return [
      'All',
      'Thoroughbred',
      'Non-Thoroughbred'
    ];
  }

  get eligibleItems () {
    return [
      'All',
      'Eligible',
      'Ineligible'
    ];
  }

  get sireItems () {
    return [
      'All',
      'Exist',
      'Non-Exist',
      'NO RECORD'
    ];
  }

  get damItems () {
    return [
      'All',
      'Exist',
      'Non-Exist',
      'NO RECORD'
    ];
  }

  get runnersItems () {
    return [
      'All',
      'Runner',
      'Not Runner'
    ];
  }

  get stakesItems () {
    return [
      'All',
      'Stakeswinners',
      'Non-Stakeswinners'
    ];
  }

  get currencies () {
    return this
        .countries.map((item: Country) => item.currency)
        .filter((item: string) => !!item)
        .sort((a: string, b: string) => a > b ? - 1 : 1);
  }
}
