











































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Data extends Vue {
  mounted () {
    if (this.$route.name === 'Data') {
      this.$router.push({ name: 'Data.horse' })
    }
  }
}
