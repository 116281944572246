









import { Component, Prop, Vue } from 'vue-property-decorator';
import HorseFinder from '@/components/HorseFinder.vue';
import { HorseDetailResource, HorseFinderResource } from '@/store/types';
import { Socket } from 'vue-socket.io-extended';

@Component({
  components: { HorseFinder }
})
export default class HorseFinderField extends Vue {
  @Prop()
  label!: string;

  @Prop()
  value!: number | null;

  horseName = '';
  finderDialog = false;
  horseDetailHash = 0;
  loading = false

  setHorse (horse: HorseFinderResource) {
    this.horseName = horse.HorseName;
    this.$emit('input', horse.HorseID);
  }

  findHorse (horseId: number) {
    this.horseDetailHash = Math.random();
    this.$socket.client.emit('horses_details', { horseId: horseId, userData: this.horseDetailHash });
    this.loading = true;
  }

  @Socket('horses_details')
  onDetailsLoaded (detail: { result: HorseDetailResource; userData?: number }) {
    if (detail.userData) {
      if (detail.userData !== this.horseDetailHash) return;
    }
    const details: HorseDetailResource = detail.result;
    this.horseName = details.HorseName;
    this.loading = false;
  }

  clear () {
    this.$emit('input', null);
  }

  created() {
    if (this.value) {
      this.findHorse(this.value)
    }
  }

}
