


























import {Vue, Component, Prop} from 'vue-property-decorator'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

@Component
export default class ImageUploader extends Vue {
  dialog = false
  image = ''
  cropperInstance: null | Cropper = null
  loading = false

  @Prop({
    type: Number,
    required: false,
    default: null
  })
  aspectRatio!: number | null

  openFileSelector() {
    (this.$refs.input as HTMLElement).click()
  }

  selectFile(event: Event) {
    const files = (event.target as HTMLInputElement).files
    if (files && files[0]) {
      const image = files[0]
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target?.result as string
        setTimeout(() => {
          this.dialog = true
          setTimeout(() => {
            if (this.cropperInstance) {
              this.cropperInstance.replace(this.image)
            } else {
              this.cropperInstance = new Cropper(this.$refs.image as HTMLImageElement, {
                viewMode: 2,
                aspectRatio: this.aspectRatio ? this.aspectRatio : undefined
              })
            }
          })
        })
      }
      reader.readAsDataURL(image)
    }
  }

  upload() {
    if (this.cropperInstance) {
      this.loading = true
      this.cropperInstance.disable()
      this.cropperInstance.getCroppedCanvas().toBlob(async (blob) => {
        if (blob) {
          const buffer = await blob.arrayBuffer()
          this.$emit('ready', buffer)
          this.dialog = false
          this.loading = false
        }
      })
    }
  }
}
