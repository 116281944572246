import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import StallionMatch from '@/views/StallionMatch.vue';
import StallionMatchFarms from '@/views/StallionMatch/FarmList2.vue';
import StallionMatchStallions from '@/views/StallionMatch/StallionsList.vue';
import FarmUpdate from '@/views/StallionMatch/FarmUpdate.vue';
import StallionUpdate from '@/views/StallionMatch/StallionUpdate.vue';
import FarmMember from '@/views/StallionMatch/FarmMember.vue';
import Data from '@/views/Data.vue';
import DataDashboard from '@/views/Data/DataDashboard.vue';
import HorseList from '@/views/Data/HorseList.vue';
import RunnerList from '@/views/Data/RunnerList.vue';
import StakeRaceList from '@/views/Data/StakeRaceList.vue';
import StakeWinnerList from '@/views/Data/StakeWinnerList.vue';
import HorseUpdate from '@/views/Data/HorseUpdate.vue';
import NewHorse from '@/views/Data/NewHorse.vue';
import AccessDenied from '@/views/errors/AccessDenied.vue';
import Report from '@/views/Report.vue';
import SalesCatalog from '@/views/Report/SalesCatalog2.vue';
import SalesCatalogCalendar from '@/components/DynamicSummary/CalendarView.vue';
import BroodmareReport from '@/views/Report/BroodmareReport.vue';
import SalesCatalogItem from '@/views/Report/SalesCatalogItem.vue';
import SalesCatalogDetails from '@/components/SalesCatalogDetails.vue';
import SalesCatalogData from '@/components/SalesCatalogData.vue';
import Member from '@/views/Member.vue';
import Logs from '@/views/Logs.vue';
import MergedHorse from '@/views/logs/MergedHorse.vue';
import DeletedHorse from '@/views/logs/DeletedHorse.vue';
import StallionLog from '@/views/logs/StallionLog.vue';
import FarmsLog from '@/views/logs/FarmsLog.vue';
import DataFeed1 from '@/views/logs/DataFeed1.vue';
import DataFeed2 from '@/views/logs/DataFeed2.vue';
import DataFeed3 from '@/views/logs/DataFeed3.vue';
import RenamedHorse from '@/views/logs/RenamedHorse.vue';
import Analytics from '@/views/Analytics.vue';
import DataFeedReportWeekly from '@/views/Analytics/DataFeedReportWeekly.vue';
import DataFeedReportMonthly from '@/views/Analytics/DataFeedReportMonthly.vue';
import InvoiceDetails from '@/views/logs/InvoiceDetails.vue';
import Promo from '@/views/logs/Promo.vue';
import ReportSalesHypoOrders from '@/views/Report/ReportSalesHypoOrders.vue';
import SubscriptionPackage from '@/views/Members/SubscriptionPackage.vue';
import MemberSubscription from '@/views/Members/MemberSubscription.vue';
import MemberSubscriptionHistory from '@/views/Members/MemberSubscriptionHistory.vue';
import Dashboard from '@/views/Dashboard.vue';
import StallionDashboard from '@/views/StallionMatch/StallionDashboard.vue';
import MemberDashboard from '@/views/Members/MemberDashboard.vue';
import FeedDashboard from '@/views/Analytics/FeedDashboard.vue';
import ReportDashboard from '@/views/Report/ReportDashboard.vue';
import ReportSalesCatalogueOrders from "@/views/Report/ReportSalesCatalogueOrders.vue";
import AdminUsersList from '@/views/Admin/admin-users-list.vue'


Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    component: Dashboard,
    name: 'Dashboard',
    path: '/dashboard'
  },
  {
    component: StallionMatch, name: 'StallionMatch', path: '/stallion-match',
    children: [
      { name: 'StallionMatch.dashboard', component: StallionDashboard, path: 'dashboard' },
      { name: 'StallionMatch.farms', component: StallionMatchFarms, path: 'farms' },
      { name: 'StallionMatch.stallions', component: StallionMatchStallions, path: 'stallions' },
      { name: 'StallionMatch.farms.new', component: FarmUpdate, path: 'farms/new' },
      { name: 'StallionMatch.farms.update', component: FarmUpdate, path: 'farms/:id' },
      { name: 'StallionMatch.stallions.new', component: StallionUpdate, path: 'stallions/new' },
      { name: 'StallionMatch.stallions.update', component: StallionUpdate, path: 'stallions/:id' },
      { name: 'StallionMatch.farms.update.member', component: FarmMember, path: 'farms/:id/members' }
    ]
  },
  {
    component: Data, name: 'Data', path: '/data',
    children: [
      { name: 'Data.dashboard', component: DataDashboard, path: 'dashboard' },
      { name: 'Data.horse', component: HorseList, path: 'horse' },
      { name: 'Data.horse.new', component: NewHorse, path: 'horse/new' },
      { name: 'Data.horse.update', component: HorseUpdate, path: 'horse/:id' },
      { name: 'Data.runner', component: RunnerList, path: 'runner' },
      { name: 'Data.stakeRace', component: StakeRaceList, path: 'stake-race' },
      { name: 'Data.stakeWinner', component: StakeWinnerList, path: 'stake-winner' },
    ]
  },
  {
    component: Report, name: 'Reports', path: '/reports',
    children: [
      { name: 'Report.Dashboard', component: ReportDashboard, path: 'dashboard' },
      { name: 'Report.SalesCatalog', component: SalesCatalog, path: 'sales-catalog' },
      { name: 'Report.SalesCatalogCalendar', component: SalesCatalogCalendar, path: 'sales-catalog-calendar' },
      { name: 'Report.BroodmareReport', component: BroodmareReport, path: 'broodmare' },
      { name: 'Report.ReportSalesHypoOrders', component: ReportSalesHypoOrders, path: 'ReportSalesHypoOrders' },
      { name: 'Report.ReportSalesCatalogueOrders', component: ReportSalesCatalogueOrders, path: 'ReportSalesCatalogueOrders' },
    ]
  },
  {
    name: 'Report.SalesCatalog.Item', component: SalesCatalogItem, path: '/reports/sales-catalog/:id',
    children: [
      {
        name: 'Report.SalesCatalog.Item.Details',
        component: SalesCatalogDetails,
        path: '/reports/sales-catalog/:id/details'
      },
      {
        name: 'Report.SalesCatalog.Item.Data',
        component: SalesCatalogData,
        path: '/reports/sales-catalog/:id/data'
      }
    ]
  },
  {
    name: 'member',
    component: Member,
    path: '/members',
    children: [
      {
        name: 'member.Dashboard',
        component: MemberDashboard,
        path: '/members/dashboard'
      },
      {
        name: 'member.SubscriptionPackage',
        component: SubscriptionPackage,
        path: '/members/subscription-package'
      },
      {
        name: 'member.MemberSubscription',
        component: MemberSubscription,
        path: '/member/member-subscription'
      }, {
        name: 'member.MemberSubscriptionHistory',
        component: MemberSubscriptionHistory,
        path: '/member/member-subscription-history'
      }
    ]
  },
  {
    name: 'Logs',
    component: Logs,
    path: '/logs',
    children: [
      {
        name: 'Logs.horse.renamed',
        component: RenamedHorse,
        path: '/logs/horse-renamed'
      },
      {
        name: 'Logs.horse.merge',
        component: MergedHorse,
        path: '/logs/horse-merged'
      },
      {
        name: 'Logs.horse.delete',
        component: DeletedHorse,
        path: '/logs/horse-delete'
      },
      {
        name: 'Logs.stallion',
        component: StallionLog,
        path: '/logs/stallion'
      },
      {
        name: 'Logs.farm',
        component: FarmsLog,
        path: '/logs/farm'
      },
      {
        name: 'Logs.Promo',
        component: Promo,
        path: '/logs/promo'
      },
      {
        name: 'Logs.apiFeed1',
        component: DataFeed1,
        path: '/logs/api-feed-1'
      },
      {
        name: 'Logs.apiFeed2',
        component: DataFeed2,
        path: '/logs/api-feed-2'
      },
      {
        name: 'Logs.apiFeed3',
        component: DataFeed3,
        path: '/logs/api-feed-3'
      },
      {
        name: 'Logs.invoice',
        component: InvoiceDetails,
        path: '/logs/invoice'
      }
    ]
  },
  {
    name: 'Analytics',
    path: '/analytics',
    component: Analytics,
    children: [
      {
        name: 'Analytics.Dashboard',
        path: '/analytics/dashboard',
        component: FeedDashboard
      },
      {
        name: 'Analytics.data.week',
        path: '/analytics/data-load-weekly',
        component: DataFeedReportWeekly
      },
      {
        name: 'Analytics.data.month',
        path: '/analytics/data-load-monthly',
        component: DataFeedReportMonthly
      }
    ]
  },
  {
    name: 'Admin',
    component: AdminUsersList,
    path: '/admin'
  },
  { component: AccessDenied, name: 'errors.accessDenied', path: '/errors/accessDenied' }
];

const router = new VueRouter({
  routes
});

export default router;
