











































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Report extends Vue {
  mounted () {
    if (this.$store.getters.user.accessLevel < 2) {
      this.$router.push({ name: 'errors.accessDenied' })
    }
    if (this.$route.name === 'Reports') {
      this.$router.push({ name: 'Report.SalesCatalog' })
    }
  }
}
