




































































import {Vue,Component} from "vue-property-decorator"

@Component
export default class Logs extends Vue {
  mounted() {
    if (this.$store.getters.user.accessLevel < 3) {
      this.$router.push({name: 'errors.accessDenied'})
    }
    if (this.$route.name === 'Logs') {
      this.$router.push({name: 'Logs.horse.renamed'})
    }
  }
}
