























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DatePicker extends Vue {
  date: string | Array<string> | null = null
  menu = false

  @Prop()
  label!: string

  @Prop()
  placeholder!: string

  @Prop({ type: Boolean })
  range!: boolean

  @Prop({ default: null })
  value!: string | null

  @Watch('value')
  onValueChange () {
    this.date = this.value
  }

  submit () {
    (this.$refs.menu as any).save(this.date)
    if (Array.isArray(this.date)) {
      if (this.date.length === 1) {
        this.$emit('input', this.date + '|' + this.date)
      } else {
        this.$emit('input', this.date.join('|'))
      }
    } else {
      if (this.range) {
        this.$emit('input', this.date + '|' + this.date)
      } else {
        this.$emit('input', this.date)
      }
    }
  }
}
