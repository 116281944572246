var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('horse-filter',{attrs:{"filters":_vm.filters,"show-reset":""},on:{"search":_vm.horseListOptionsHandler,"new":function($event){return _vm.$router.push({name:'Data.horse.new'})},"update:filters":function($event){_vm.filters=$event},"reset":_vm.reset}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('horse-detail',{attrs:{"item":_vm.selectedHorse},on:{"update":function($event){_vm.$router.push({name:'Data.horse.update', params: {id: _vm.selectedHorse.HorseID.toString()}})}},model:{value:(_vm.horseDetailsDialog),callback:function ($$v) {_vm.horseDetailsDialog=$$v},expression:"horseDetailsDialog"}}),(_vm.firstTry)?_c('v-alert',{attrs:{"type":_vm.isFilterUsedError?'error':'info',"color":_vm.isFilterUsedError?'error':'info'}},[_vm._v(" Please Use Some Of Filters. ")]):_vm._e(),(!_vm.firstTry)?_c('v-data-table',{attrs:{"items":_vm.horseSummary.items,"server-items-length":_vm.horseSummary.finalLength,"loading":_vm.loadings.horseSummary,"items-per-page":18,"page":_vm.horsesSummaryPage,"headers":_vm.HorsesListHeaders,"disable-filtering":"","hide-default-footer":"","fixed-header":""},on:{"click:row":_vm.openHorseDetail,"update:page":function($event){_vm.horsesSummaryPage=$event},"update:sort-desc":_vm.setSortDesc,"update:sort-by":_vm.setSortBy},scopedSlots:_vm._u([{key:"item.sire_x_dam",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"pr-2 grey--text text--darken-1",staticStyle:{"font-size":"0.6rem"}},[_vm._v(" x ")]),_c('div',{staticClass:"d-flex flex-column justify-space-between grey--text text--darken-2"},[_c('div',{staticStyle:{"font-size":"0.7rem"}},[_vm._v(_vm._s(item.HorseSireName || '-'))]),_c('div',{staticStyle:{"font-size":"0.7rem"}},[_vm._v(_vm._s(item.HorseDamName || '-'))])])])]}},{key:"item.HorseRunCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[(item.HorseRunCount > 0)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('div',{staticStyle:{"border":"solid 1px #BDBDBD","margin-top":"6px","width":"24px","height":"24px","border-radius":"100%"}})],1)]}},{key:"item.HorseWinCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[(item.HorseWinCount > 0)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('div',{staticStyle:{"border":"solid 1px #BDBDBD","margin-top":"6px","width":"24px","height":"24px","border-radius":"100%"}})],1)]}},{key:"item.HorseCreatedAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.HorseCreatedAt,'DD.MM.YYYY')))])]}},{key:"item.HorseDependencyCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_vm._v(" "+_vm._s(item.HorseDependencyCount)+" ")]),_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{attrs:{"to":{name:'Data.horse.update', params: {id: item.HorseID.toString()}}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteHorse(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("delete")])],1)],1)],1)],1)]}}],null,false,2375076464),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}):_vm._e(),(!_vm.firstTry)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"pagination",attrs:{"total-visible":7,"color":"secondary","length":_vm.horseSummaryPageLength},model:{value:(_vm.horsesSummaryPage),callback:function ($$v) {_vm.horsesSummaryPage=$$v},expression:"horsesSummaryPage"}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.deleteLoading),callback:function ($$v) {_vm.deleteLoading=$$v},expression:"deleteLoading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Please stand by "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }