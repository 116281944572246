<template>
  <v-app id="app">
    <navigation v-if="!needLogin"/>
    <v-main class="grey lighten-3" v-if="!needLogin">
      <router-view/>
    </v-main>
    <login-page v-if="needLogin" @done="loginDone"/>
    <v-snackbar v-model="unauthorized" color="error" bottom centered>
      Access Denied!
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
.container {
  width: 95vw
}

html, body, body .v-application, *:not(i):not(.i):not(.custom-font) {
  font-family: 'Fira Sans', sans-serif !important;
}

@font-face {
  font-family: Gilroy;
  src: url("assets/gilroy-medium.otf") format("opentype");
}

.custom-font {
  font-weight: 500;
  font-size: 1.7rem;
  font-family: Gilroy, serif !important;
}


.theme--light {
  *::-webkit-scrollbar {
    width: 4px;

    @media only screen and (min-device-width: 960px) {
      width: 8px;
    }
  }

  *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
  }
}

.rounded-t-nl {
  border-top-left-radius: 18px !important;
  border-top-right-radius: 18px !important;
}


.rounded-nl {
  border-radius: 18px !important;
}
</style>

<style lang="scss">
.tab-item {
  width: 100%;
  text-align: center;
  line-height: 48px;
  display: block;
  background-color: #f5f5f5;

  > div {
    height: 48px;
    display: inline-block;
    border-bottom: solid 2px transparent;
  }

  &.router-link-active {
    background-color: #FFFFFF;

    > div {
      border-bottom: solid 2px var(--v-secondary-base);
    }
  }
}

.pagination {
  li button:not(.v-pagination__item--active) {
    background-color: #fafafa !important;
    color: #818181 !important
  }
}
</style>

<script>
import Navigation from '@/components/Navigation'
import LoginPage from '@/views/LoginPage'

export default {
  components: {LoginPage, Navigation},
  data: () => ({
    needLogin: true,
    unauthorized: false
  }),
  created() {
    this.$socket.client.on('exception', (data) => {
      if (data.message === 'Forbidden resource') {
        this.unauthorized = true
      }
    })
    this.$socket.client.io.on('reconnect', () => {
      const refreshToken = localStorage.getItem('refreshToken')
      const accessToken = localStorage.getItem('accessToken')
      console.log(new Date(), {
        accessToken, refreshToken
      })
      this.$socket.client.emit('login:refresh', {
        accessToken, refreshToken
      })
    })
  },
  methods:{
    loginDone() {
      this.needLogin = false
      this.$socket.client.emit('authorities_list', this.$store.getters.user.memberID)
    }
  }
}
</script>
