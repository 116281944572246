































import {Component, Vue} from "vue-property-decorator"

@Component
export default class StallionMatch extends Vue {
  mounted() {
    if (this.$store.getters.user.accessLevel < 3) {
      this.$router.push({name: 'errors.accessDenied'})
    }
    if (this.$route.name === 'StallionMatch') {
      this.$router.push({name: 'StallionMatch.farms'})
    }
  }
}
