

















import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Socket} from "vue-socket.io-extended";
import throttle from "lodash.throttle";
import {Country} from "@/store/types";

interface FarmFinderResource {
  FarmID: number;
  FarmName: string;
}

@Component
export default class FarmFinder extends Vue {
  dialog = false
  loading = false
  name = ''
  farms: Array<{ FarmName: string; FarmID: number }> = []
  header = [
    {text: 'NAME', value: 'FarmName'},
  ]

  requestFetchDataThrottled: ReturnType<typeof throttle> = throttle(this.requestFetchData.bind(this), 500)

  requestFetchData(name: string) {
    this.$socket.client.emit('farm_finder', {name})
  }

  @Watch('name')
  onNameChange() {
    if (this.name.length > 2) {
      this.loading = true
      this.requestFetchDataThrottled(this.name)
    } else {
      this.farms = []
    }
  }

  @Socket('farm_finder')
  onHorsesChange(items: {
    result: Array<{ FarmName: string; FarmID: number }>;
    name: string;
  }) {
    if (items.name === this.name) {
      this.loading = false
      this.farms = items.result
    }
  }

  select(item: { FarmName: string; FarmID: number }) {
    this.$emit('select', item)
    this.$emit('input', false)
  }

  @Watch('value')
  onValueChange() {
    this.dialog = this.value
  }

  onKeyPress (e: any) {
    if (e.code === "Escape") {
      this.$emit('input',false)
    }
  }

  @Prop()
  value!: boolean
}
