

































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Country, HorseFilterResource, HorseSummaryResource } from '@/store/types';
import HorseFilter from '@/components/HorseFilter.vue';
import HorseDetail from '@/components/HorseDetail.vue';
import Moment from 'moment';
import { Dictionary } from 'vue-router/types/router';
import { Socket } from 'vue-socket.io-extended';

import throttle from 'lodash.throttle';

@Component({
  components: { HorseDetail, HorseFilter }
})
export default class HorseList extends Vue {
  horseDetailsDialog = false;
  selectedHorse: HorseSummaryResource = {
    HorseID: 0,
    HorseName: '',
    HorseSex: 'M',
    HorseYoB: 0,
    HorseCoB: '',
    HorseSireName: '',
    HorseSireYoB: '',
    HorseSireCoB: '',
    HorseDamName: '',
    HorseDamYoB: '',
    HorseDamCoB: '',
    HorseRunCount: 0,
    HorseWinCount: 0,
    HorseFamilyTrackingCount: 0,
    HorseDependencyCount: 0,
    HorseCreatedAt: '',
    HorseFrom: '',
    HorseVerifiedBy: '',
    HorseSoundexCode: '',
    HorseNameKey: '',
    HorseCoBID: 0,
    HorseSireID: 0,
    HorseDamID: 0
  };
  firstTry = true;
  isFilterUsedError = false;

  openHorseDetail (item: HorseSummaryResource) {
    this.selectedHorse = item;
    this.horseDetailsDialog = true;
  }

  filters: HorseFilterResource = {
    horseName: '', // done
    exactMatch: true, // done
    country: null, // done
    state: null, // done
    from: null, // done
    to: null, // done
    horseFrom: null, // done
    yobMin: null, // done
    yobMax: null, // done
    sireId: null, //done
    damId: null, // done
    thoroughbred: 'All', // done
    eligible: 'All', // done
    sireStatus: 'All', // done
    damStatus: 'All', // done
    runner: 'All', // done
    stakesWinners: 'All', // done
    prizeMin: null,
    prizeMax: null,
    prizeCurrency: null,
    options: {
      unverified: false, // done
      familyTracking: false,
      stallionMatch: false,
      horseInformation: false,
      missingCOB: false, // done
      missingYOF: false // done
    }
  };

  selectedItem = [];
  HorsesListHeaders = [
    {
      text: 'HORSE NAME',
      value: 'HorseName'
    },
    {
      text: 'SEX',
      value: 'HorseSex'
    },
    {
      text: 'YOB',
      value: 'HorseYoB'
    },
    {
      text: 'COUNTRY',
      value: 'HorseCoB'
    },
    {
      text: 'SIRE x DAM',
      value: 'sire_x_dam',
      sortable: false
    },
    {
      text: 'CREATED',
      value: 'HorseCreatedAt'
    },
    {
      text: 'RNR',
      value: 'HorseRunCount'
    },
    {
      text: 'SWNR',
      value: 'HorseWinCount'
    },
    {
      text: 'MY MARES',
      value: 'HorseFamilyTrackingCount'
    },
    {
      text: '# PROGENY',
      value: 'HorseDependencyCount'
    }
  ];
  horsesSummaryPage = 1;

  get loadings () {
    return this.$store.getters.loading;
  }

  @Watch('horsesSummaryPage')
  horseListPageHandler (page: number) {
    this.$store.dispatch('loadHorsesSummary', { page: page - 1, pageSize: this.horseSummary.perPage });
  }

  objectToQueryString (object: { [key: string]: string | null | number | boolean }): Dictionary<string> {
    const res: Dictionary<string> = {};
    for (const key in object) {
      res[key] = String(object[key]);
    }
    return res;
  }

  horseListOptionsHandler () {
    let isFilterUsed = false;
    const query: { [key: string]: string | null | number | boolean } = {};
    if (this.filters.horseName?.length || 0 > 0) {
      isFilterUsed = true;
      query['HorseName'] = this.filters.horseName; // have
      query['exactMatch'] = this.filters.exactMatch; // have
    }
    if (this.filters.yobMax) {
      isFilterUsed = true;
      query['yobMax'] = this.filters.yobMax; //have
    }
    if (this.filters.yobMin) {
      isFilterUsed = true;
      query['yobMin'] = this.filters.yobMin; // have
    }
    if (this.filters.country) {
      isFilterUsed = true;
      query['HorseCountryID'] = this.filters.country.CountryID; // have
    }
    if (this.filters.from?.length || 0 > 0) {
      isFilterUsed = true;
      query['from'] = Moment(this.filters.from).toISOString(); //have
    }
    if (this.filters.to?.length || 0 > 0) {
      isFilterUsed = true;
      query['to'] = Moment(this.filters.to).toISOString(); // have
    }
    if (this.filters.horseFrom) {
      isFilterUsed = true;
      query['horseFrom'] = this.filters.horseFrom; // have
    }
    if (this.filters.options.unverified !== null && typeof this.filters.options.unverified !== 'undefined') {
      isFilterUsed = isFilterUsed || this.filters.options.unverified;
      query['unverified'] = this.filters.options.unverified; // have
    }
    if (this.filters.options.missingCOB !== null && typeof this.filters.options.missingCOB !== 'undefined') {
      isFilterUsed = isFilterUsed || this.filters.options.missingCOB;
      query['missingCOB'] = this.filters.options.missingCOB; // have
    }
    if (this.filters.options.missingYOF !== null && typeof this.filters.options.missingYOF !== 'undefined') {
      isFilterUsed = isFilterUsed || this.filters.options.missingYOF;
      query['missingYOF'] = this.filters.options.missingYOF; // have
    }
    if (this.filters.damId !== null) {
      isFilterUsed = true;
      query['damId'] = this.filters.damId; // have
    }
    if (this.filters.sireId !== null) {
      isFilterUsed = true;
      query['sireId'] = this.filters.sireId;  // have
    }
    if (this.filters.thoroughbred !== 'All') {
      isFilterUsed = true;
      query['thoroughbred'] = this.filters.thoroughbred === 'Thoroughbred'; // have
    }
    if (this.filters.eligible !== 'All') {
      isFilterUsed = true;
      query['eligible'] = this.filters.eligible === 'Eligible'; // have
    }
    if (this.filters.sireStatus !== 'All') {
      isFilterUsed = true;
      query['sireStatus'] = this.filters.sireStatus; // have
    }
    if (this.filters.damStatus !== 'All') {
      isFilterUsed = true;
      query['damStatus'] = this.filters.damStatus; // have
    }
    if (this.filters.runner !== 'All') {
      isFilterUsed = true;
      query['runner'] = this.filters.runner === 'Runner'; // have
    }
    if (this.filters.stakesWinners !== 'All') {
      isFilterUsed = true;
      query['stakesWinners'] = this.filters.stakesWinners === 'Stakeswinners'; // have
    }
    if (this.filters.prizeMax) {
      isFilterUsed = true;
      query['prizeMax'] = this.filters.prizeMax; // have
    }
    if (this.filters.prizeMin) {
      isFilterUsed = true;
      query['prizeMin'] = this.filters.prizeMin; // have
    }
    if (this.filters.prizeCurrency) {
      isFilterUsed = true;
      query['prizeCurrency'] = this.filters.prizeCurrency; // have
    }

    let sort: any = undefined;
    if (this.sortColName) {
      sort = {
        name: this.sortColName,
        DESC: this.sortDesc
      };
      console.log('1', sort, this.sortColName);
    }
    if (isFilterUsed || sort) {
      this.firstTry = false;
      this.isFilterUsedError = false;
      this.$router.push({ query: this.objectToQueryString(query) });
      this.$store.dispatch('initHorsesSummaryPagination', { query, pageSize: 18, sort });
    } else {
      this.isFilterUsedError = true;
      this.firstTry = true;
    }
  }

  get countries () {
    return this.$store.getters.countries;
  }

  @Watch('$route', { immediate: true })
  onRouteChange () {
    const query = this.$route.query;
    if (typeof query.HorseName === 'string') {
      this.filters.horseName = query.HorseName;
    }
    if (typeof query.exactMatch === 'string') {
      this.filters.exactMatch = query.exactMatch === 'true';
    }
    if (typeof query.HorseCountryID === 'string') {
      this.filters.country = this.countries.find((c: Country) => c.CountryID === Number(query.HorseCountryID));
    }
    if (typeof query.from === 'string') {
      this.filters.from = query.from;
    }
    if (typeof query.horseFrom === 'string') {
      this.filters.horseFrom = query.horseFrom;
    }
    if (typeof query.unverified === 'string') {
      this.filters.options.unverified = query.unverified === 'true';
    }
    if (typeof query.missingCOB === 'string') {
      this.filters.options.missingCOB = query.missingCOB === 'true';
    }
    if (typeof query.missingYOF === 'string') {
      this.filters.options.missingYOF = query.missingYOF === 'true';
    }
    if (typeof query.prizeCurrency === 'string') {
      this.filters.prizeCurrency = query.prizeCurrency;
    }
    if (typeof query.prizeMin === 'string') {
      this.filters.prizeMin = Number(query.prizeMin);
    }
    if (typeof query.prizeMax === 'string') {
      this.filters.prizeMax = Number(query.prizeMax);
    }
    if (typeof query.stakesWinners === 'string') {
      this.filters.stakesWinners = query.stakesWinners === 'true' ? 'Stakeswinners' : 'Non-Stakeswinners';
    }
    if (typeof query.runner === 'string') {
      this.filters.runner = query.runner === 'true' ? 'Runner' : 'Not Runner';
    }
    if (typeof query.sireStatus === 'string') {
      this.filters.sireStatus = query.sireStatus;
    }
    if (typeof query.damStatus === 'string') {
      this.filters.damStatus = query.damStatus;
    }
    if (typeof query.eligible === 'string') {
      this.filters.eligible = query.eligible === 'true' ? 'Eligible' : 'Ineligible';
    }
    if (typeof query.thoroughbred === 'string') {
      this.filters.thoroughbred = query.thoroughbred === 'true' ? 'Thoroughbred' : 'Non-Thoroughbred';
    }
    if (typeof query.damId === 'string') {
      this.filters.damId = Number(query.damId);
    }
    if (typeof query.sireId === 'string') {
      this.filters.sireId = Number(query.sireId);
    }
  }

  get horseSummary () {
    return this.$store.getters.horseSummary;
  }

  get horseSummaryPageLength () {
    return Math.ceil(this.horseSummary.finalLength / 18);
  }

  deleteLoading = false;

  deleteHorse (horse: HorseSummaryResource) {
    if (confirm('Are you sure?')) {
      this.deleteLoading = true;
      this.$socket.client.emit('horses_delete', {
        horseID: horse.HorseID
      });
    }
  }

  @Socket('horses_delete')
  onHorseDeleted (res: string | true) {
    if (res !== true) {
      alert(res);
    } else {
      this.$emit('deleted');
    }
    this.deleteLoading = false;
  }

  reset () {
    this.filters = {
      horseName: '', // done
      exactMatch: true, // done
      country: null, // done
      state: null, // done
      from: null, // done
      to: null, // done
      horseFrom: null, // done
      yobMin: null, // done
      yobMax: null, // done
      sireId: null, //done
      damId: null, // done
      thoroughbred: 'All', // done
      eligible: 'All', // done
      sireStatus: 'All', // done
      damStatus: 'All', // done
      runner: 'All', // done
      stakesWinners: 'All', // done
      prizeMin: null,
      prizeMax: null,
      prizeCurrency: null,
      options: {
        unverified: false, // done
        familyTracking: false,
        stallionMatch: false,
        horseInformation: false,
        missingCOB: false, // done
        missingYOF: false // done
      }
    };
    this.$router.push({ query: {} });
    setTimeout(() => {
      this.horseListOptionsHandler();
    });
  }

  sortDesc = false;
  sortColName: string | null = '';

  setSortBy (value: Array<string>) {
    this.sortColName = value[0] || null;
    setTimeout(this.updateSortState.bind(this));
  }

  setSortDesc (value: Array<boolean | undefined>) {
    const tmp = !!value[0];
    if (tmp !== this.sortDesc) {
      this.sortDesc = tmp;
      setTimeout(this.updateSortState.bind(this));
    }
  }

  updateSortState = throttle(this.updateSortStateHandler.bind(this), 500);

  updateSortStateHandler () {
    this.horseListOptionsHandler();
  }
}
