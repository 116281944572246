















































































































































































































import {Vue, Component} from "vue-property-decorator"
import {Socket} from "vue-socket.io-extended";

@Component
export default class NewSalesCatalog extends Vue {
  step = 1
  successMessage = false
  // general
  catalogName = ''
  code = ''
  startDate = ''
  endDate = ''
  country = null
  isPublic = false
  isHIP = false
  isOnline = false
  // meta
  startDateMenu = false
  endDateMenu = false

  // info
  salesTypeId: null | number = null
  infoName = ''
  infoDescription = ''
  infoType = null
  infoCountry = null

  // company
  companyID: null | number = null
  companyName = ''
  companyCountry = null
  companyAddress = ''
  companyWebsite = ''


  get countries() {
    return this.$store.getters.countries
  }

  get loadings() {
    return this.$store.getters.loading
  }

  get salesCatalogInfo() {
    return this.$store.getters.salesCatalogInfo
  }

  get companies() {
    const c = this.salesCatalogInfo.companies
    c.push({
      SalesCompanyID: 0,
      SalesCompanyName: 'Create New Company',
      countryName: '',
      SalesCompanyCountryID: 1,
      SalesCompanyCountryName: '',
      SalesCompanyWebsite: ''
    })
    return c
  }

  get saleInfos() {
    const c = this.salesCatalogInfo.infos
    c.push({
      SalesInfoID: 0,
      SalesInfoName: 'Create New Sales Catalog Type',
      SalesInfoDescription: '',
      SalesInfoTypeName: '',
      SalesInfoTypeID: 0,
      SalesInfoTypeDescription: '',
      SalesInfoCountryID: 0,
      SalesInfoCountryName: '',
    })
    return c
  }

  get salesInfoTypes() {
    return this.salesCatalogInfo.types
  }

  loading = false
  createCompanyPromise: (() => void) | null = null
  createSalesInfoPromise: (() => void) | null = null
  createSalePromise: (() => void) | null = null

  createCompany(): Promise<void> {
    this.$socket.client.emit('create_sales_company', {
      name: this.companyName,
      countryID: this.companyCountry,
      address: this.companyAddress,
      website: this.companyWebsite
    })
    return new Promise(r => {
      this.createCompanyPromise = r
    })
  }

  createSalesInfo(): Promise<void> {
    this.$socket.client.emit('create_sales_info', {
      name: this.infoName,
      salesTypeId: this.infoType,
      countryId: this.infoCountry,
      description: this.infoDescription
    })
    return new Promise(r => {
      this.createSalesInfoPromise = r
    })
  }

  createSale(): Promise<void> {
    this.$socket.client.emit('create_sales', {
      isHIP: this.isHIP,
      isPublic: this.isPublic,
      isOnlineSales: this.isOnline,
      salesInfoId: this.salesTypeId,
      salesCompanyId: this.companyID,
      countryId: this.country,
      startDate: this.startDate,
      endDate: this.endDate,
      salesName: this.catalogName,
      salesCode: this.code
    })
    return new Promise(r => {
      this.createSalePromise = r
    })
  }

  salesID = 0;

  @Socket('create_sales')
  onSalesCreated({salesID}: { salesID: number }) {
    this.salesID = salesID
    if (this.createSalePromise) {
      this.createSalePromise()
    }
  }

  @Socket('create_sales_info')
  onSalesInfoCreated({salesID}: { salesID: number }) {
    this.salesTypeId = salesID
    if (this.createSalesInfoPromise) {
      this.createSalesInfoPromise()
    }
  }

  @Socket('create_sales_company')
  onSalesCompanyCreated({salesID}: { salesID: number }) {
    this.companyID = salesID
    if (this.createCompanyPromise) {
      this.createCompanyPromise()
    }
  }

  async create() {
    this.loading = true
    if (this.companyID === 0) {
      await this.createCompany()
    }
    if (this.salesTypeId === 0) {
      await this.createSalesInfo()
    }
    await this.createSale()
    this.loading = false
    setTimeout(() => {
      this.$emit('close')
    }, 3000)
  }
}
