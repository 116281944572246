




































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Analytics extends Vue {
  render = false


  mounted () {
    if (this.$store.getters.user.accessLevel < 3) {
      this.$router.push({ name: 'errors.accessDenied' })
    }
    if (this.$route.name === 'Analytics') {
      this.$router.push({ name: 'Analytics.data.week' })
    }
  }
}
